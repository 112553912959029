import { Inject, Injectable, Optional } from '@angular/core';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { HttpClient } from '@angular/common/http';
import {
  User,
  UserAdditionalInfo,
  UserType,
  UserTypeName,
} from '@tremaze/shared/feature/user/types';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
} from '@tremaze/shared/util-http';
import { concat, map, Observable, of } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import {
  USER_MODULE_CONFIG,
  UserModuleConfig,
} from '@tremaze/shared/feature/user/module-config';
import { RemoteRoleDataSource } from '@tremaze/shared/permission/data-access';
import { delay, switchMap } from 'rxjs/operators';
import { RemoteCCUserAllocationDataSource } from '@tremaze/shared/feature/user/feature/allocation/data-access';
import { UserInstitution } from '@tremaze/shared/feature/user/feature/allocation/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { PrivilegeName, Role } from '@tremaze/shared/permission/types';
import { ReferencePersonService } from '@tremaze/shared/feature/reference-person';
import { everyTrue } from '@tremaze/shared/util/rxjs';
import { UnapprovedUserCountService } from '@tremaze/shared/feature/user/feature/unapproved-user-count';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';
import { CustomFormFillOutResult } from '@tremaze/shared/feature/custom-forms/feature/fill-out';

interface CreateDTO
  extends Required<
    Pick<
      User,
      'address' | 'username' | 'firstName' | 'lastName' | 'other' | 'enabled'
    >
  > {
  birth: string;
  email: string;
  phone: string;
  mobile: string;
  instId: string;
  password: string;
  userTypeIds: string[];
  genderId: string;
  roleIds: string[];
  avatarId?: string;
  fake?: boolean;
  customFormSubmissions?: CustomFormFillOutResult[];
  internalFileNumber?: string;
  externalFileNumber?: string;
  personnelNumber?: string;
}

interface EditDTO
  extends Omit<CreateDTO, 'password' | 'instId' | 'roleIds' | 'fake'> {
  userId: string;
  password?: string;
  fake?: boolean;
}

export type UserPaginationOptions = DataSourceMethodsPaginatedOptions<User> & {
  userTypeIdentifier?: UserTypeName[];
};

interface LoginAccessData {
  password?: string;
  fake?: boolean;
}

@Injectable({ providedIn: 'root' })
export class RemoteUserDataSource extends DefaultCRUDDataSourceImpl<User> {
  deserializer = User.deserialize;
  controller = '/users';
  filterFields = [
    'FIRST_NAME',
    'LAST_NAME',
    'USERNAME',
    'INTERNAL_FILE_NUMBER',
    'EXTERNAL_FILE_NUMBER',
    'PERSONNEL_NUMBER',
    'ZIP_CODE',
    'CITY',
  ];

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    @Optional() protected roleDataSource?: RemoteRoleDataSource,
    @Optional()
    @Inject(USER_MODULE_CONFIG)
    protected readonly moduleConfig?: UserModuleConfig,
  ) {
    super();
  }

  create(
    i: User,
    options?: DataSourceMethodsCreateOptions<User>,
    setAsReferenceClient?: boolean,
    customFormSubmissions?: CustomFormFillOutResult[],
  ): Observable<User> {
    const payload: CreateDTO = {
      address: i.address,
      genderId: i.gender?.id,
      username: i.username,
      birth: i.birth?.toJSON(null, true),
      email: i.contact?.email,
      phone: i.contact?.phone,
      mobile: i.contact?.mobile,
      instId: i.userInstitutions?.length
        ? i.userInstitutions[0].institution?.id
        : options?.instIds?.[0],
      lastName: i.lastName,
      firstName: i.firstName,
      other: i.other,
      password: i.password?.length ? i.password : undefined,
      userTypeIds: i.userTypes?.map((u) => u.id),
      enabled: i.enabled,
      roleIds: i.roles?.map((r) => r.id),
      avatarId: i.profileImage?.id,
      fake: i.isFakeAccount,
      customFormSubmissions,
      internalFileNumber: i.internalFileNumber,
      externalFileNumber: i.externalFileNumber,
      personnelNumber: i.personnelNumber,
    };
    if (!this.moduleConfig?.enableSetPasswordOnUserCreation) {
      delete payload.password;
    }
    let result$: Observable<User>;
    if (this.moduleConfig?.disableUserInstitutionController) {
      result$ = super.create(payload as any, options);
    } else {
      result$ = super.create(payload as any, {
        ...(options ?? {}),
        controller: '/users/institutions',
      });
    }
    return result$.pipe(
      switchMap((result) => {
        if (!result?.id?.length) {
          throw new Error('User creation failed');
        }
        if (setAsReferenceClient) {
          return this.setUserAsOwnReferenceClient(result.id).pipe(
            map(() => result),
          );
        }
        return of(result);
      }),
    );
  }

  edit(
    i: User,
    options?: DataSourceMethodsEditOptions<User>,
    customFormSubmissions?: CustomFormFillOutResult[],
    loginAccessData: LoginAccessData = {},
  ): Observable<User> {
    const payload: EditDTO = {
      userId: i.id,
      address: i.address,
      genderId: i.gender?.id,
      username: i.username,
      birth: i.birth?.toJSON(null, true),
      email: i.contact?.email,
      phone: i.contact?.phone,
      mobile: i.contact?.mobile,
      lastName: i.lastName,
      firstName: i.firstName,
      other: i.other,
      userTypeIds: i.userTypes?.map((u) => u.id),
      enabled: i.enabled,
      avatarId: i.profileImage?.id,
      customFormSubmissions,
      internalFileNumber: i.internalFileNumber,
      externalFileNumber: i.externalFileNumber,
      personnelNumber: i.personnelNumber,
      ...loginAccessData,
    };
    return super.edit(payload as any, options);
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions,
  ): Observable<boolean> {
    if (options?.instIds?.length) {
      return concat(
        ...options.instIds.map((instId) =>
          super
            .deleteById(id, {
              ...options,
              q: { instId },
              instIds: undefined,
            })
            .pipe(delay(500)),
        ),
      ).pipe(everyTrue());
    }
    return super.deleteById(id, options);
  }

  getAllUserTypes(): Observable<UserType[]> {
    return this.http.get<UserType[]>('/public/userTypes');
  }

  getUserTypeByStringIdentifier(
    stringIdentifier: string,
  ): Observable<UserType> {
    return this.getAllUserTypes().pipe(
      map((types) => types.find((t) => t.name === stringIdentifier)),
    );
  }

  getPaginated(
    options?: UserPaginationOptions,
    forPrivilege?: PrivilegeName[],
  ): Observable<Pagination<User>> {
    options ||= {};
    if (!options?.controller) {
      if (forPrivilege?.length) {
        options.controller = '/permissions/users';
        options.q = {
          ...(options?.q ?? {}),
          privileges: forPrivilege?.join(','),
        };
      }
    }
    if (options?.userTypeIdentifier) {
      options.q = {
        ...(options?.q ?? {}),
        userTypeIdentifier: options.userTypeIdentifier,
      };
    }
    return super.getPaginated(options);
  }

  protected setUserAsOwnReferenceClient(userId: string): Observable<boolean> {
    return this.http.put<boolean>(
      `/users/me/addReferenceClient/${userId}`,
      null,
    );
  }

  getAdditionalInfoForUserById(userId: string): Observable<UserAdditionalInfo> {
    return this.http
      .get(`${this.controller}/${userId}/additionalInfo`)
      .pipe(map(UserAdditionalInfo.deserialize));
  }

  setAdditionalInfoForUserById(
    userId: string,
    info: UserAdditionalInfo,
  ): Observable<UserAdditionalInfo> {
    const payload = {
      nationality: info.nationality?.code,
      housingSituation: info.housingSituation,
      employmentStatus: info.employmentStatus,
      maritalStatus: info.maritalStatus,
      educationalQualification: info.educationalQualification,
      healthData: info.healthData,
      amountHouseholdMembers: info.amountHouseholdMembers,
    };

    return this.http
      .post(`${this.controller}/${userId}/additionalInfo`, payload)
      .pipe(map(UserAdditionalInfo.deserialize));
  }

  uploadFileToUserDir(userId: string, file: File): Observable<FileStorage> {
    const formData = new FormData();
    formData.append('files', file);
    return this.http
      .post<TremazeHttpResponse<any>>(`/dirs/users/${userId}/upload`, formData)
      .pipe(map((res) => FileStorage.deserialize(res.object?.[0])));
  }

  getUserByInternalReferenceId(internalReferenceId: string): Observable<User> {
    return this.getPaginated({
      filter: {
        filterValue: internalReferenceId,
        filterFields: ['INTERNAL_FILE_NUMBER'],
        page: 0,
        pageSize: 1,
      },
    }).pipe(
      map((pagination) => pagination.content[0]),
      switchMap((r) => this.getFreshById(r.id)),
    );
  }

  getUserByExternalReferenceId(externalReferenceId: string): Observable<User> {
    return this.getPaginated({
      filter: {
        filterValue: externalReferenceId,
        filterFields: ['EXTERNAL_FILE_NUMBER'],
        page: 0,
        pageSize: 1,
      },
    }).pipe(
      map((pagination) => pagination.content[0]),
      switchMap((r) => this.getFreshById(r.id)),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteUserUnapprovedDataSource extends RemoteUserDataSource {
  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    private referencePersonService: ReferencePersonService,
    private unapprovedUserCountService: UnapprovedUserCountService,
    @Optional()
    @Inject(USER_MODULE_CONFIG)
    protected readonly moduleConfig?: UserModuleConfig,
    @Optional() protected roleDataSource?: RemoteRoleDataSource,
    @Optional() private allocationDataSource?: RemoteCCUserAllocationDataSource,
  ) {
    super(http, js, roleDataSource, moduleConfig);
  }

  readonly deserializer = (data) =>
    User.deserialize({ ...(data ?? {}), isUnapproved: true });

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<User>,
  ): Observable<Pagination<User>> {
    return super.getPaginated({
      controller: '/users/unapproved',
      ...(options ?? {}),
    });
  }

  approveUser(
    user: User,
    institution: Institution,
    departments: Department[],
    createAsMyReferenceClient: boolean,
    institutionRoles?: Role[],
  ): Observable<boolean> {
    const reqs: Observable<unknown>[] = [];
    if (institution) {
      if (this.allocationDataSource) {
        reqs.push(
          this.allocationDataSource.saveAllocation(user.id, [
            {
              userInstitution: new UserInstitution(
                null,
                null,
                institution,
                institutionRoles ?? [],
              ),
              departments,
            },
          ]),
        );
      } else {
        console.warn(
          "Can't allocate user to selected institution since RemoteCCUserAllocationDataSource was not provided",
        );
      }
    }
    reqs.push(
      ...[
        this.http.post<boolean>(
          `/users/approveLockedAccount/${user.id}`,
          null,
          { params: { approved: 'true' } },
        ),
        this.edit(user),
      ],
    );
    if (user.roles?.length) {
      reqs.push(this.roleDataSource.setRolesToUser(user, user.roles));
    }
    if (createAsMyReferenceClient) {
      reqs.push(
        this.setUserAsOwnReferenceClient(user.id).pipe(
          switchMap(() => this.referencePersonService.reload()),
        ),
      );
    }
    return concat(...reqs).pipe(map(() => true));
  }
}

@Injectable({ providedIn: 'root' })
export class UnapprovedUsersCountDataSource {
  constructor(protected http: HttpClient) {}

  getUnapprovedUsersCount(): Observable<number> {
    return this.http.get<number>('/users/unapproved/count');
  }
}
