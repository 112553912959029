import { Institution } from '@tremaze/shared/feature/institution/types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Observable, switchMap } from 'rxjs';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSource,
  DefaultCRUDDataSourceImpl,
  DefaultREADDataSourceWithPagination,
  DefaultREADDataSourceWithPaginationImpl,
} from '@tremaze/shared/util-http';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { Pagination } from '@tremaze/shared/models';
import { map } from 'rxjs/operators';
import { Division } from '@tremaze/shared/feature/division/types';

export abstract class InstitutionREADDataSource extends DefaultREADDataSourceWithPagination<Institution> {
  abstract getPaginated(
    options?: DataSourceMethodsPaginatedOptions,
    forPrivilege?: PrivilegeName[],
  ): Observable<Pagination<Institution>>;

  abstract getAll(): Observable<Institution[]>;
}

@Injectable({ providedIn: 'root' })
export class RemoteInstitutionREADDataSourceDefaultImpl
  extends DefaultREADDataSourceWithPaginationImpl<Institution>
  implements InstitutionREADDataSource
{
  controller = '/public/institution';

  protected deserializer = Institution.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  getAll(): Observable<Institution[]> {
    return this.http
      .get<GetAllDTO>(this.controller + '/all')
      .pipe(map((data) => data.items));
  }
}

interface GetAllDTO {
  count: number;
  items: Institution[];
}

interface CreateDTO
  extends Pick<
    Institution,
    'name' | 'contactMail' | 'displayMail' | 'address'
  > {
  email: string;
  phone: string;
  mobile: string;
  registrationMail: string;
  divisionIds?: string[];
}

interface EditDTO extends Omit<CreateDTO, 'divisionId'> {
  instId: string;
}

function institutionToCreateDTO(
  i: Institution,
  divisions?: Division[],
): CreateDTO {
  const res: CreateDTO = {
    address: i.address,
    displayMail: i.displayMail,
    contactMail: i.contactMail,
    name: i.name,
    mobile: i.contact?.mobile,
    phone: i.contact?.phone,
    email: i.contact?.email,
    registrationMail: i.registrationMail,
  };

  if (divisions?.length) {
    res.divisionIds = divisions.map((d) => d.id);
  }

  return res;
}

function institutionToEditDTO(i: Institution): EditDTO {
  return {
    ...institutionToCreateDTO(i),
    instId: i.id,
  };
}

export abstract class InstitutionCRUDDataSource
  extends DefaultCRUDDataSource<Institution>
  implements InstitutionREADDataSource
{
  abstract getAll(): Observable<Institution[]>;

  abstract create(
    i: Institution & { division?: Division },
    options?: DataSourceMethodsCreateOptions<Institution>,
  ): Observable<Institution>;
}

@Injectable({ providedIn: 'root' })
export class RemoteInstitutionCRUDDataSourceDefaultImpl
  extends DefaultCRUDDataSourceImpl<Institution>
  implements InstitutionCRUDDataSource
{
  deserializer = Institution.deserialize;
  controller = '/institutions';
  filterFields = ['NAME'];

  constructor(
    public http: HttpClient,
    public js: JsonSerializer,
  ) {
    super();
  }

  create(
    i: Institution & { divisions?: Division[] },
    options?: DataSourceMethodsCreateOptions<Institution>,
  ): Observable<Institution> {
    return super.create(
      institutionToCreateDTO(i, i.divisions) as never,
      options,
    );
  }

  edit(
    i: Institution,
    options?: DataSourceMethodsEditOptions<Institution>,
  ): Observable<Institution> {
    return this.getFreshById(i.id).pipe(
      map((fresh) => {
        const payload = institutionToEditDTO(i);
        delete payload['name'];
        return {
          ...payload,
          description: fresh.description,
        };
      }),
      switchMap((payload) => super.edit(payload as never, options)),
    );
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<Institution>,
    forPrivilege?: PrivilegeName[],
  ): Observable<Pagination<Institution>> {
    options ||= {};
    if (forPrivilege?.length) {
      options.controller = '/permissions/institutions';
      options.q = {
        ...(options?.q ?? {}),
        privileges: forPrivilege?.join(','),
      };
    }
    return super.getPaginated(options);
  }

  getAll(): Observable<Institution[]> {
    console.error('THIS SHOULD NOT BE CALLED');
    return undefined;
  }
}
