import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { Address } from '@tremaze/shared/models';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'tremaze-address-edit',
  templateUrl: './address-edit.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AddressEditComponent {
  @Input() formGroup: FormGroup<Address>;

  private _required?: boolean;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
}
