import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'tremaze-overwrite-or-create-copy-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  template: `
    <h2 mat-dialog-title>Datei speichern</h2>
    <mat-dialog-content>
      <p>
        Die Datei existiert bereits. Möchten Sie die Datei überschreiben oder
        eine Kopie erstellen?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close="OVERWRITE">Überschreiben</button>
      <button mat-button mat-dialog-close="CREATE_COPY">Kopie erstellen</button>
    </mat-dialog-actions>
  `,
})
export class OverwriteOrCreateCopyDialogComponent {}
