import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  first,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
} from 'rxjs';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import { NotificationService } from '@tremaze/shared/notification';
import { FolderViewEventsService } from './folder-view-events.service';
import { SelectedFilesAndFoldersContextmenuService } from '@tremaze/shared/feature/file-storage/ui/selected-files-and-folders-contextmenu';
import { FolderViewDeletionService } from './folder-view-deletion.service';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';

@Injectable()
export class FolderViewSelectionService {
  private readonly _confirmationService = inject(ConfirmationService);
  private readonly _dataSource = inject(FolderViewDataSource);
  private readonly _notificationService = inject(NotificationService);
  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _eventsService = inject(FolderViewEventsService);
  private readonly _deletionService = inject(FolderViewDeletionService);
  private readonly _selectedFilesAndFoldersContextmenuService = inject(
    SelectedFilesAndFoldersContextmenuService,
  );
  readonly selectedFiles$ = new BehaviorSubject<FileStorage[]>([]);
  readonly selectedDirectories$ = new BehaviorSubject<DirStorage[]>([]);
  readonly hasSelection$: Observable<boolean> = combineLatest([
    this.selectedFiles$,
    this.selectedDirectories$,
  ]).pipe(
    map(([files, directories]) => !!files.length || !!directories.length),
  );

  private _isSingleSelection = false;
  private _selectionRegex$ = new BehaviorSubject<RegExp | undefined>(undefined);

  readonly selectionRegex$ = this._selectionRegex$.asObservable();

  clearSelection() {
    this.selectedFiles$.next([]);
    this.selectedDirectories$.next([]);
  }

  setSingleSelection(value: boolean) {
    this._isSingleSelection = value;
  }

  setSelectionRegex(value?: RegExp) {
    this._selectionRegex$.next(value);
  }

  setSelectedFiles(files: FileStorage[]) {
    this.selectedFiles$.next(files);
  }

  setSelectedDirectories(directories: DirStorage[]) {
    this.selectedDirectories$.next(directories);
  }

  private async _deleteSelectedFilesAndFolders() {
    const confirmation = await firstValueFrom(
      this._confirmationService.askUserForConfirmation({ warn: true }),
    );

    if (!confirmation?.confirmed) {
      return;
    }

    const selectedFiles = this.selectedFiles$.value;
    const selectedFolders = this.selectedDirectories$.value;

    if (selectedFiles.length || selectedFolders.length) {
      const requests = [];

      if (selectedFiles.length) {
        requests.push(
          this._dataSource.deletesFiles(...selectedFiles.map((f) => f.id)),
        );
      }

      if (selectedFolders.length) {
        requests.push(
          this._dataSource.deleteDirectories(
            ...selectedFolders.map((d) => d.id),
          ),
        );
      }

      forkJoin(requests).subscribe(() => {
        this._notificationService.showNotification('Elemente gelöscht');
        this._eventsService.refreshCurrentFiles$.next();
        this._eventsService.refreshCurrentSubDirectories$.next();
      });
    }
  }

  isDirectorySelected(dir: DirStorage) {
    return this.selectedDirectories$.value.some((d) => d.id === dir.id);
  }

  isFileSelected(file: FileStorage) {
    return this.selectedFiles$.value.some((f) => f.id === file.id);
  }

  switchDirectorySelection(dir: DirStorage) {
    if (this._isSingleSelection) {
      this.selectedDirectories$.next([dir]);
      this.selectedFiles$.next([]);
      return;
    }
    if (this.isDirectorySelected(dir)) {
      this.selectedDirectories$.next(
        this.selectedDirectories$.value.filter((d) => d.id !== dir.id),
      );
    } else {
      const canSelect = !dir.tenantDir && !dir.institution;

      if (!canSelect) {
        return;
      }

      this.selectedDirectories$.next([...this.selectedDirectories$.value, dir]);
    }
  }

  switchFileSelection(file: FileStorage) {
    if (this._selectionRegex$.value) {
      if (!this._selectionRegex$.value.test(file.fileType)) {
        return;
      }
    }

    if (this._isSingleSelection) {
      this.selectedFiles$.next([file]);
      this.selectedDirectories$.next([]);
      return;
    }
    if (this.isFileSelected(file)) {
      this.selectedFiles$.next(
        this.selectedFiles$.value.filter((f) => f.id !== file.id),
      );
    } else {
      this.selectedFiles$.next([...this.selectedFiles$.value, file]);
    }
  }

  setSelection(files: FileStorage[], directories: DirStorage[]) {
    this.selectedFiles$.next(files);
    this.selectedDirectories$.next(directories);
  }

  selectAll(
    files$: Observable<FileStorage[]>,
    directories$: Observable<DirStorage[]>,
  ) {
    combineLatest([files$, directories$])
      .pipe(first())
      .subscribe(([files, directories]) => {
        this.selectedFiles$.next(files);
        this.selectedDirectories$.next(
          directories.filter((d) => d.canBeTinkeredWith),
        );
      });
  }

  async openSelected(currentDirectory$: Observable<DirStorage>) {
    const currentDirectory = await firstValueFrom(currentDirectory$);
    const result = await firstValueFrom(
      this._selectedFilesAndFoldersContextmenuService.showContextMenu({
        selectedFolders: this.selectedDirectories$.value,
        selectedFiles: this.selectedFiles$.value,
        currentFolder: currentDirectory,
      }),
    );

    if (result) {
      if (result.action === 'DELETE_ALL') {
        this._deleteSelectedFilesAndFolders();
      } else if (result.action === 'DELETE_ONE') {
        const target = result.target;
        if (!target) {
          return;
        }
        // noinspection SuspiciousTypeOfGuard
        if (target instanceof FileStorage) {
          this._deletionService.deleteFile(target);
        } else {
          // noinspection SuspiciousTypeOfGuard
          if (target instanceof DirStorage) {
            this._deletionService.deleteDirectory(target);
          }
        }
      } else if (result.action === 'DOWNLOAD_ONE') {
        const target = result.target;
        if (target instanceof FileStorage) {
          this._fileStorageService.downloadFile(target);
        }
      }
    }
  }
}
