import { Component, Inject } from '@angular/core';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { SelectedFilesAndFolderContextmenuResponse } from '@tremaze/shared/feature/file-storage/ui/selected-files-and-folders-contextmenu/types';
import { TzPermissionRequest } from '@tremaze/shared/permission/types';
import { Observable } from 'rxjs';
import { FileStoragePermissionsService } from '@tremaze/shared/feature/file-storage/feature/file-storage-permissions';

@Component({
  selector: 'tremaze-selected-files-and-folders-contextmenu',
  templateUrl: './selected-files-and-folders-contextmenu.component.html',
  styleUrls: ['./selected-files-and-folders-contextmenu.component.scss'],
})
export class SelectedFilesAndFoldersContextmenuComponent {
  files: FileStorage[];
  folders: DirStorage[];
  currentFolder: DirStorage;

  get deletePermissionRequest(): TzPermissionRequest {
    return {
      gPerms: 'FILE_STORAGE_DELETE',
    };
  }

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    data: {
      selectedFiles: FileStorage[];
      selectedFolders: DirStorage[];
      currentFolder: DirStorage;
    },
    private ref: MatBottomSheetRef<SelectedFilesAndFoldersContextmenuComponent>,
    private permissionsService: FileStoragePermissionsService,
  ) {
    this.files = data?.selectedFiles || [];
    this.folders = data?.selectedFolders || [];
    this.currentFolder = data?.currentFolder;
  }

  async onClickDeleteFolder(folder: DirStorage) {
    this.deleteItem(folder);
  }

  onClickDownloadFolder(folder: DirStorage) {
    this.downloadItem(folder);
  }

  onClickDeleteFile(file: FileStorage) {
    this.deleteItem(file);
  }

  onClickDownloadFile(file: FileStorage) {
    this.downloadItem(file);
  }

  onClickDownloadAll() {
    const r: SelectedFilesAndFolderContextmenuResponse = {
      action: 'DOWNLOAD_ALL',
    };
    this.ref.dismiss(r);
  }

  hasDeletePermission(target: FileStorage | DirStorage): Observable<boolean> {
    return this.permissionsService.hasDeletePermissionForStorage(
      target,
      this.currentFolder,
    );
  }

  async onClickDeleteAll() {
    const r: SelectedFilesAndFolderContextmenuResponse = {
      action: 'DELETE_ALL',
    };
    this.ref.dismiss(r);
  }

  private downloadItem(target: DirStorage | FileStorage) {
    const r: SelectedFilesAndFolderContextmenuResponse = {
      target,
      action: 'DOWNLOAD_ONE',
    };
    this.ref.dismiss(r);
  }

  private async deleteItem(target: DirStorage | FileStorage) {
    const r: SelectedFilesAndFolderContextmenuResponse = {
      target,
      action: 'DELETE_ONE',
    };
    this.ref.dismiss(r);
  }
}
