import { inject, Injectable } from '@angular/core';
import { FolderViewDataSource } from '../folder-view-data-source';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { DIR_STORAGE_DATA_SOURCE_CONFIG } from './config';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { forkJoin, Observable, of, take } from 'rxjs';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';
import { map, switchMap } from 'rxjs/operators';
import { ensureObservable } from '@tremaze/shared/util/rxjs';
import { Meta, Pagination } from '@tremaze/shared/models';
import { removeUndefined } from '@tremaze/shared/util-utilities';
import { FileStorageDataSource } from '../file-storage-data-source';
import { filterResponse, reportProgressPercent } from '../helpers';

type SearchResult = {
  absolutePath: string;
  absoluteViewPath: string;
  fileSize: number;
  fileType?: string;
  id: string;
  instId?: string;
  name: string;
  viewName: string;
  type: 'DIR' | 'FILE';
  tenantDir?: boolean;
};

@Injectable()
export class FolderViewDataSourceImpl implements FolderViewDataSource {
  private readonly _fileStorageDataSource = inject(FileStorageDataSource);
  private readonly _http = inject(HttpClient);
  private readonly _config? = inject(DIR_STORAGE_DATA_SOURCE_CONFIG, {
    optional: true,
  });

  deletesFiles(...ids: string[]): Observable<void> {
    return this._fileStorageDataSource.deletesFiles(...ids);
  }

  deleteDirectories(...ids: string[]): Observable<void> {
    if (ids.length === 0) {
      return of();
    }
    return this._http.delete<void>(`dirs`, {
      params: { dirIds: ids.join(',') },
    });
  }

  createDirectory(p: {
    directoryName: string;
    parentId: string;
  }): Observable<DirStorage> {
    const payload = {
      name: p.directoryName,
      parentDirId: p.parentId,
    };
    return this._http
      .post<TremazeHttpResponse<DirStorage>>(`dirs`, payload)
      .pipe(map((o) => DirStorage.deserialize(o.object)));
  }

  private _getNormalizedPath$(path: string): Observable<string> {
    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }

    if (this._config?.basePath) {
      return ensureObservable(this._config.basePath).pipe(
        take(1),
        map((basePath) => {
          if (path.length === 0) {
            return basePath;
          }
          if (path.startsWith(basePath)) {
            return path;
          }
          if (!basePath.endsWith('/')) {
            basePath += '/';
          }
          if (path.startsWith('/')) {
            return basePath + path.slice(1);
          }
          return basePath + path;
        }),
      );
    }
    if (path.startsWith('/')) {
      return of(path);
    }
    return of('/' + path);
  }

  updateDirectoryName(p: {
    directoryId: string;
    newName: string;
  }): Observable<DirStorage> {
    return this._http
      .put<TremazeHttpResponse<DirStorage>>(
        `dirs/${p.directoryId}/rename`,
        null,
        {
          params: { newName: p.newName },
        },
      )
      .pipe(map((o) => DirStorage.deserialize(o.object)));
  }

  getDirectoryByAbsolutePath(path: string): Observable<DirStorage> {
    const URL = 'dirs/absolutePath';
    return this._getNormalizedPath$(path).pipe(
      switchMap((path) =>
        this._http
          .get<DirStorage>(URL, {
            params: new HttpParams({ fromObject: { absolutePath: path } }),
          })
          .pipe(map((o) => DirStorage.deserialize(o))),
      ),
    );
  }

  getFilesForDirectory(directoryId: string): Observable<FileStorage[]> {
    return this._http
      .get<FileStorage[]>(`dirs/${directoryId}/files`)
      .pipe(
        map((r) =>
          r
            .map(FileStorage.deserialize)
            .sort((a, b) => a.fileViewname.localeCompare(b.fileViewname)),
        ),
      );
  }

  getSubDirectoriesByAbsolutePath(
    absolutPath: string,
  ): Observable<DirStorage[]> {
    return this.getDirectoryByAbsolutePath(absolutPath).pipe(
      map((d) =>
        d.subDirs.sort((a, b) => a.dirViewname.localeCompare(b.dirViewname)),
      ),
    );
  }

  searchDirsAndFiles(p: {
    searchValue: string;
    searchFromDirId?: string | undefined;
  }): Observable<(FileStorage | DirStorage)[]> {
    const containsSearchValue = `%${p.searchValue}%`;

    return this._http
      .get<Pagination<SearchResult>>(`dirs/filter`, {
        params: removeUndefined({
          viewNameFilter: containsSearchValue,
          startDirId: p.searchFromDirId,
          page: 0,
          size: 50,
        }),
      })
      .pipe(
        map((r) => {
          return r.content
            .map((entry) => {
              const type: 'DIR' | 'FILE' = entry['type'];
              if (type === 'DIR') {
                return new DirStorage(
                  entry.id,
                  new Meta(),
                  entry.name,
                  entry.viewName,
                  entry.absolutePath,
                  entry.absoluteViewPath,
                  [],
                  { id: '' },
                  [],
                  entry.instId ? { id: entry.instId, name: '' } : undefined,
                  entry.tenantDir,
                );
              } else {
                return new FileStorage(
                  entry.id,
                  new Meta(),
                  entry.viewName,
                  entry.name,
                  entry.fileSize,
                  0,
                  0,
                  entry.fileType ?? '',
                  undefined,
                  undefined,
                  entry.absolutePath,
                );
              }
            })
            .sort((a, b) => {
              function getSortValue(o: FileStorage | DirStorage) {
                return o instanceof FileStorage
                  ? o.fileViewname
                  : o.dirViewname;
              }
              return getSortValue(a).localeCompare(getSortValue(b));
            });
        }),
      );
  }

  private _moveFilesToDirectory(p: {
    fileIds: string[];
    destinationDirId: string;
  }) {
    return this._http.post<{ code: number }>(`dirs/moveFiles`, null, {
      params: {
        destDirId: p.destinationDirId,
        fileIds: p.fileIds,
      },
    });
  }

  private _moveDirectoriesToDirectory(p: {
    folderIds: string[];
    destinationDirId: string;
  }) {
    return this._http.post<{ code: number }>(`dirs/moveDirs`, null, {
      params: {
        destDirId: p.destinationDirId,
        dirIds: p.folderIds,
      },
    });
  }

  moveFilesAndDirectoriesToPath(p: {
    fileIds: string[];
    folderIds: string[];
    absolutePath: string;
  }): Observable<void> {
    if (p.fileIds.length === 0 && p.folderIds.length === 0) {
      return of();
    }

    return this.getDirectoryByAbsolutePath(p.absolutePath)
      .pipe(
        switchMap((dir) => {
          const requests: Observable<{ code: number }>[] = [];

          if (p.fileIds.length > 0) {
            requests.push(
              this._moveFilesToDirectory({
                fileIds: p.fileIds,
                destinationDirId: dir.id,
              }),
            );
          }

          if (p.folderIds.length > 0) {
            requests.push(
              this._moveDirectoriesToDirectory({
                folderIds: p.folderIds,
                destinationDirId: dir.id,
              }),
            );
          }
          return forkJoin(requests);
        }),
      )
      .pipe(
        map((r) => {
          if (r.some((o) => o.code !== 0)) {
            throw new Error('Failed to move files and directories');
          }
        }),
      );
  }

  uploadFilesToDirectory(p: {
    files: File[];
    destinationDirId: string;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage[]> {
    const uploadData = new FormData();
    p.files.forEach((f) => {
      uploadData.append('files', f);
    });

    return this._http
      .request<TremazeHttpResponse<FileStorage[]>>(
        new HttpRequest(
          'POST',
          `dirs/${p.destinationDirId}/upload`,
          uploadData,
          {
            reportProgress: true,
            params: new HttpParams({ fromObject: { 'ngsw-bypass': '' } }),
          },
        ),
      )
      .pipe(
        reportProgressPercent(p.onProgress),
        filterResponse(),
        map((event) => event.body),
        map((body) => {
          if (!body) {
            throw new Error('Failed to upload files');
          }
          return body;
        }),
        map((r) => r.object.map((f) => FileStorage.deserialize(f))),
      );
  }

  uploadFilesToPath(p: {
    files: File[];
    absolutePath: string;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage[]> {
    return this.getDirectoryByAbsolutePath(p.absolutePath).pipe(
      switchMap((dir) =>
        this.uploadFilesToDirectory({
          files: p.files,
          destinationDirId: dir.id,
          onProgress: p.onProgress,
        }),
      ),
    );
  }
}

export const provideFolderViewDataSource = () => ({
  provide: FolderViewDataSource,
  useClass: FolderViewDataSourceImpl,
});
