import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { Linearicon } from '@tremaze/shared/ui';
import { MatTooltip } from '@angular/material/tooltip';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import animations from '../animations';
import {
  MatMenu,
  MatMenuContent,
  MatMenuItem,
  MatMenuTrigger,
} from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { Observable } from 'rxjs';
import { FolderViewItemNameInputComponent } from '../folder-view-item-name-input/folder-view-item-name-input.component';

@Component({
  selector:
    'tremaze-folder-view-folder-item, a[tremaze-folder-view-folder-item], button[tremaze-folder-view-folder-item]',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatTooltip,
    ReactiveFormsModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    MatIconButton,
    MatRipple,
    MatMenuContent,
    FolderViewItemNameInputComponent,
  ],
  templateUrl: './folder-view-folder-item.component.html',
  styleUrl: './folder-view-folder-item.component.scss',
  animations: [animations.itemAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-folder-item folder-view-item',
    '[@item]': '',
  },
})
export class FolderViewFolderItemComponent {
  @ViewChild('input') private _input?: ElementRef<HTMLInputElement>;

  @Input() folder?: DirStorage;

  @Output() readonly submitFolderName = new EventEmitter<string>();
  @Output() readonly cancelCreateFolder = new EventEmitter<void>();
  @Output() readonly deleteFolder = new EventEmitter<void>();
  @Output() readonly openFolder = new EventEmitter<void>();

  @HostBinding('class.folder-view-item--Selected')
  @Input()
  selected = false;

  @Input() canWrite$?: Observable<boolean>;

  private _formControl?: FormControl;

  get formControl(): FormControl | undefined {
    return this._formControl;
  }

  private _isCreateFolderItem = false;
  private _isEditingFolderName = false;

  @Input({ transform: booleanAttribute })
  get isCreateFolderItem(): boolean {
    return this._isCreateFolderItem;
  }

  set isCreateFolderItem(value: boolean) {
    this._isCreateFolderItem = value;
    this._updateFormControl();
  }

  @Input({ transform: booleanAttribute })
  get isEditingFolderName(): boolean {
    return this._isEditingFolderName;
  }

  set isEditingFolderName(value: boolean) {
    this._isEditingFolderName = value;
    this._updateFormControl();
  }

  private _updateFormControl() {
    if (this._isCreateFolderItem || this._isEditingFolderName) {
      this._formControl = new FormControl(
        this.folder?.dirViewname ?? null,
        Validators.required,
      );

      setTimeout(() => {
        this._input?.nativeElement.focus();
      });
    } else {
      this._formControl?.reset();
      this._formControl = undefined;
    }
  }

  get isSystemDir(): boolean {
    return !!this.folder?.tenantDir || !!this.folder?.institution;
  }

  get icon(): Linearicon {
    if (this.isSystemDir) {
      return 'lnr-folder-star';
    }

    return 'lnr-folder';
  }

  private _submitIfValid() {
    if (this._formControl?.valid) {
      if (
        this.formControl!.pristine ||
        this.formControl!.value === this.folder?.dirViewname
      ) {
        this.onCancel();
      }
      this.submitFolderName.emit(this._formControl.value.trim());
      this._formControl.reset();
      this._formControl = undefined;
      this.isEditingFolderName = false;
    }
  }

  onSubmitText() {
    this._submitIfValid();
  }

  @HostListener('keyup.escape')
  onCancel() {
    if (this.isCreateFolderItem) {
      this.cancelCreateFolder.emit();
    } else if (this.isEditingFolderName) {
      this.isEditingFolderName = false;
    }
  }

  onLostFocusOnNameInput() {
    if (this.formControl?.pristine) {
      this.onCancel();
    }
  }

  onClickRenameFolder() {
    this.isEditingFolderName = true;
  }
}
