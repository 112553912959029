import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Linearicon } from '@tremaze/shared/ui';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatIconButton } from '@angular/material/button';
import { SharedFileStorageUiSingleFileDisplayModule } from '@tremaze/shared/feature/file-storage/ui/single-file-display';
import { MatMenuModule } from '@angular/material/menu';
import animations from '../animations';
import { Observable } from 'rxjs';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { FolderViewItemNameInputComponent } from '../folder-view-item-name-input/folder-view-item-name-input.component';

@Component({
  selector:
    'tremaze-folder-view-file-item, figure[tremaze-folder-view-file-item]',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatIconButton,
    SharedFileStorageUiSingleFileDisplayModule,
    MatMenuModule,
    ReactiveFormsModule,
    FolderViewItemNameInputComponent,
  ],
  templateUrl: './folder-view-file-item.component.html',
  styleUrl: './folder-view-file-item.component.scss',
  animations: [animations.itemAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-file-item folder-view-item',
    '[@item]': '',
  },
})
export class FolderViewFileItemComponent {
  @Input({ required: true }) file?: FileStorage;

  @HostBinding('class.folder-view-item--Selected')
  @Input()
  selected = false;

  @HostBinding('class.folder-view-item--Disabled')
  @HostBinding('class.folder-view-file-item--Disabled')
  @Input()
  disabled = false;

  @Input() canWrite$?: Observable<boolean>;

  @Output() readonly showFilePreview = new EventEmitter<void>();
  @Output() readonly deleteFile = new EventEmitter<void>();
  @Output() readonly downloadFile = new EventEmitter<void>();
  @Output() readonly copyFileLink = new EventEmitter<void>();
  @Output() readonly submitFileName = new EventEmitter<string>();
  @Output() readonly navigateToPath = new EventEmitter<void>();

  private _formControl?: FormControl;

  get formControl(): FormControl | undefined {
    return this._formControl;
  }

  get isEditing(): boolean {
    return !!this._formControl;
  }

  get icon(): Linearicon {
    switch (this.file?.type) {
      case 'IMAGE':
        return 'lnr-picture';
      case 'PDF':
        return 'lnr-document2';
      case 'DOCUMENT':
        return 'lnr-document';
      case 'VIDEO':
        return 'lnr-film-play';
      case 'OTHER':
      default:
        return 'lnr-file-empty';
    }
  }

  get name(): string {
    return this.file?.fileViewname ?? '';
  }

  @HostListener('keyup.escape')
  onCancel() {
    if (this.isEditing) {
      this._formControl?.reset();
      this._formControl = undefined;
    }
  }

  private _submitIfValid() {
    if (this._formControl?.valid) {
      if (
        this.formControl!.pristine ||
        this.formControl!.value === this.file?.fileViewname
      ) {
        this.onCancel();
      }
      this.submitFileName.emit(this._formControl.value.trim());
      this._formControl.reset();
      this._formControl = undefined;
    }
  }

  onLostFocusOnNameInput() {
    if (this.formControl?.pristine) {
      this.onCancel();
    }
  }

  onSubmitText() {
    this._submitIfValid();
  }

  onClickEditName() {
    this._formControl = new FormControl(
      this.file?.fileViewname,
      Validators.required,
    );
  }
}
