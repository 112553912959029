import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PickerPopupComponent } from './picker-popup/picker-popup.component';
import { Observable } from 'rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[tremazePickerPopup]',
})
export class PickerPopupDirective<T extends object>
  implements OnInit, OnDestroy
{
  @Input() overlayLabel = '';
  @Input() displayWith!: (option: T) => string;
  @Input() subtitleWith?: (option: T) => string | undefined;
  @Input() getAvatar?: (option: T) => FileStorage | undefined;
  @Input() getAvatarFallbackInitials?: (option: T) => string | undefined;
  @Input({ required: true }) filteredOptions!: (
    filterValue: string,
  ) => Observable<T[]> | T[];
  @Input() inputPrefixIcon?: string;

  @Input() showAddOptionButton = false;

  private _showResetButton = false;

  @Input()
  get showResetButton(): boolean {
    return this._showResetButton;
  }

  set showResetButton(value: boolean) {
    this._showResetButton = coerceBooleanProperty(value);
  }

  @Output() optionSelected = new EventEmitter<T>();
  @Output() addOptionClicked = new EventEmitter<void>();

  private overlayRef!: OverlayRef;

  constructor(
    private readonly _overlay: Overlay,
    private readonly _elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'transparent-backdrop',
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
    });

    this.overlayRef = this._overlay.create(overlayConfig);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  ngOnDestroy(): void {
    this.overlayRef.dispose();
  }

  @HostListener('click')
  onClick(): void {
    const overlayPortal = new ComponentPortal(PickerPopupComponent<T>);
    const overlayComponent = this.overlayRef.attach(overlayPortal).instance;
    overlayComponent.filteredOptions = this.filteredOptions;
    overlayComponent.label = this.overlayLabel;
    overlayComponent.displayWith = this.displayWith;
    overlayComponent.subtitleWith = this.subtitleWith;
    overlayComponent.getAvatar = this.getAvatar;
    overlayComponent.getAvatarFallbackInitials = this.getAvatarFallbackInitials;
    overlayComponent.inputPrefixIcon = this.inputPrefixIcon;
    overlayComponent.showResetButton = this.showResetButton;
    overlayComponent.showAddOptionButton = this.showAddOptionButton;
    overlayComponent.optionSelected.subscribe((option) => {
      this.optionSelected.emit(option);
      this.overlayRef.detach();
    });
    overlayComponent.addOptionClicked.subscribe(() => {
      this.addOptionClicked.emit();
      this.overlayRef.detach();
    });
  }
}
