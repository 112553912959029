import {
  Component,
  EventEmitter,
  inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfViewerComponent } from './pdf-viewer.component';

@Component({
  selector: 'tremaze-pdf-viewer-dialog',
  template: `
    <div style="display: flex; flex-direction: column; height: 100%">
      <tremaze-pdf-viewer
        style="display: block; flex: 1;"
        [documentUrl]="url"
        [documentName]="name"
      ></tremaze-pdf-viewer>
      <div mat-dialog-actions align="end">
        <button mat-button (click)="pdfViewer?.print()">Drucken</button>
        <button mat-button (click)="onClickedSave()">Speichern</button>
        <button mat-button mat-dialog-close>Schließen</button>
      </div>
    </div>
  `,
  styleUrl: './pdf-viewer-dialog.component.scss',
})
export class PdfViewerDialogComponent {
  readonly url = inject(MAT_DIALOG_DATA).documentUrl;
  readonly name = inject(MAT_DIALOG_DATA).documentName;

  @ViewChild(PdfViewerComponent, { static: true })
  pdfViewer?: PdfViewerComponent;

  @Output() readonly save = new EventEmitter<Blob>();

  async onClickedSave() {
    const blob = await this.pdfViewer?.save();
    if (blob) {
      this.save.emit(blob);
    }
  }
}
