<tremaze-icon
  class="folder-view-folder-item__Icon"
  [icon]="icon"
  matTooltip="System-Ordner"
  [matTooltipDisabled]="!isSystemDir"
></tremaze-icon>
@if ((isCreateFolderItem || isEditingFolderName) && formControl) {
  <tremaze-folder-view-item-name-input
    class="folder-view-folder-item__Name-Input"
    [formControl]="formControl"
    (lostFocus)="onLostFocusOnNameInput()"
    (submitText)="onSubmitText()"
    (cancel)="onCancel()"
  ></tremaze-folder-view-item-name-input>
} @else if (folder) {
  <div class="folder-view-folder-item__Name">{{ folder.dirViewname }}</div>

  <button
    [matMenuTriggerFor]="menu"
    class="folder-view-folder-item__Menu-Button small-icon-button"
    (click)="$event.stopPropagation()"
    mat-icon-button
  >
    <tremaze-icon icon="lnr-ellipsis"></tremaze-icon>
  </button>
}

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="openFolder.emit()">
      <span class="folder-view-folder-item__Menu__Item__Content-Wrapper">
        <tremaze-icon
          class="folder-view-folder-item__Menu__Item__Icon"
          icon="lnr-folder"
        ></tremaze-icon>
        <span>Ordner öffnen</span>
      </span>
    </button>

    @if (folder?.canBeTinkeredWith && (!canWrite$ || (canWrite$ | async))) {
      <button mat-menu-item (click)="onClickRenameFolder()">
        <span class="folder-view-folder-item__Menu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-folder-item__Menu__Item__Icon"
            icon="lnr-pencil2"
          ></tremaze-icon>
          <span>Ordner umbenennen</span>
        </span>
      </button>

      <button mat-menu-item (click)="deleteFolder.emit()" style="color: red">
        <span class="folder-view-folder-item__Menu__Item__Content-Wrapper">
          <tremaze-icon
            class="folder-view-folder-item__Menu__Item__Icon"
            icon="lnr-trash2"
          ></tremaze-icon>
          <span>Ordner löschen</span>
        </span>
      </button>
    }
  </ng-template>
</mat-menu>
