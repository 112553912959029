import {
  FileSelectorComponent,
  FileSelectorConfig,
} from './file-selector.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FileSelectorService {
  constructor(private dialog: MatDialog) {}

  selectFiles(config?: FileSelectorConfig): Observable<FileStorage[] | null> {
    return this.dialog
      .open(FileSelectorComponent, {
        panelClass: ['dialog-no-padding', 'fullscreen'],
        data: config,
      })
      .afterClosed()
      .pipe(map((r) => (r instanceof FileStorage ? [r] : null)));
  }
}
