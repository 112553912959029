import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerModule } from './pdf-viewer.module';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog.component';

@Injectable({
  providedIn: PdfViewerModule,
})
export class PdfViewerService {
  constructor(private dialog: MatDialog) {}

  openPDF({ pdfUrl, pdfName }: { pdfUrl: string; pdfName: string }) {
    return this.dialog.open(PdfViewerDialogComponent, {
      width: 'min(1800px, 95vw)',
      height: 'min(1200px, 95vh)',
      disableClose: true,
      data: {
        documentUrl: pdfUrl + '&cacheBuster=' + Date.now(),
        documentName: pdfName,
      },
    });
  }
}
