import {
  DataSourceMethodsGetFreshOptions,
  DefaultREADDataSourceWithPaginationImpl,
  qry
} from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomFormSubmission } from '@tremaze/shared/feature/custom-forms/types';
import { combineLatestWith, map, Observable, skip } from 'rxjs';
import { Report } from '@tremaze/shared/feature/report';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

/**
 * This is a custom data source that is used to read the submissions for a form.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomFormSubmissionDataSourceDefaultImpl extends DefaultREADDataSourceWithPaginationImpl<CustomFormSubmission> {
  protected controller = 'forms/submissions';

  filterFields = [
    'FORM_NAME',
    'FILLED_OUT_FOR_USERNAME',
    'FILLED_OUT_FOR_FIRST_NAME',
    'FILLED_OUT_FOR_LAST_NAME',
    'FILLED_OUT_BY_USERNAME',
    'FILLED_OUT_BY_FIRST_NAME',
    'FILLED_OUT_BY_LAST_NAME',
  ];

  protected deserializer = CustomFormSubmission.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    private authService: AuthV2Service,
    private appConfig: AppConfigService,
  ) {
    super();
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<CustomFormSubmission> & {
      useNewestFormVersion?: boolean;
    },
  ): Observable<CustomFormSubmission> {
    const useNewestFormVersion = options?.useNewestFormVersion ?? false;
    const q: qry = {
      ...options?.q,
      newestFormVersion: useNewestFormVersion.toString(),
    };
    return super.getFreshById(id, { ...options, q });
  }

  getTotalAmountUncompletedSubmissions(config?: {
    userId?: string;
  }): Observable<number> {
    return this.http.get<number>(`${this.controller}/uncompleted/count`, {
      params: config,
    });
  }

  completeSubmission(submissionId: string): Observable<void> {
    return this.http.put<void>(
      `${this.controller}/${submissionId}/complete`,
      {},
      { params: { completed: 'true' } },
    );
  }

  generateSubmissionReport(
    submissionId: string,
    type: 'PDF' | 'CSV',
  ): Observable<Report> {
    return this.http.post<Report>(
      `${this.controller}/${submissionId}/generateReport`,
      {},
      { params: { type } },
    );
  }

  // TODO: Refactor the refresh logic. This is utter trash, new devs will hate their life.
  getSubmissionReportPreview(submissionId: string): Observable<string> {
    const path =
      this.appConfig.basePath + `${this.controller}/${submissionId}/report`;
    this.authService.refreshToken();
    return this.authService.tokenInfo$.pipe(
      skip(1),
      combineLatestWith(this.authService.activeTenant$),
      map(
        ([tokenInfo, activeTenant]) =>
          `${path}?access_token=${tokenInfo?.access_token}&tenantId=${activeTenant?.id}`,
      ),
    );
  }
}
