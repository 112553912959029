import { inject, Injectable } from '@angular/core';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { firstValueFrom } from 'rxjs';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { FolderViewDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import { NotificationService } from '@tremaze/shared/notification';
import { FolderViewEventsService } from './folder-view-events.service';

@Injectable()
export class FolderViewDeletionService {
  private readonly _dataSource = inject(FolderViewDataSource);
  private readonly _notificationService = inject(NotificationService);
  private readonly _confirmationService = inject(ConfirmationService);
  private readonly _eventsService = inject(FolderViewEventsService);

  async deleteFile(file: FileStorage) {
    const confirmation = await firstValueFrom(
      this._confirmationService.askUserForConfirmation({ warn: true }),
    );

    if (!confirmation?.confirmed) {
      return;
    }

    this._dataSource.deletesFiles(file.id).subscribe(() => {
      this._notificationService.showNotification('Datei gelöscht');
      this._eventsService.refreshCurrentFiles$.next();
    });
  }

  async deleteDirectory(dir: DirStorage) {
    if (dir.tenantDir || dir.institution) {
      return;
    }

    const confirmation = await firstValueFrom(
      this._confirmationService.askUserForConfirmation({ warn: true }),
    );

    if (!confirmation?.confirmed) {
      return;
    }

    this._dataSource.deleteDirectories(dir.id).subscribe(() => {
      this._notificationService.showNotification('Ordner gelöscht');
      this._eventsService.refreshCurrentSubDirectories$.next();
    });
  }
}
