import { Color } from '@angular-material-components/color-picker';
import { Category } from '@tremaze/shared/feature/category/types';
import { Department } from '@tremaze/shared/feature/department/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Gender } from '@tremaze/shared/feature/gender/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import { Specialization } from '@tremaze/shared/feature/specialization/types';
import { User, UserType } from '@tremaze/shared/feature/user/types';
import { Address, Meta } from '@tremaze/shared/models';
import { TremazeSchedule } from '@tremaze/shared/scheduling/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { EventBase } from './event-base';
import { EventSettings } from './event-settings';
import { EventTemplate } from './event-template';
import { Currency, Price } from './price';
import {
  TemporalRegistrationRestriction,
  TemporalRegistrationRestrictionGroup,
} from './temporal-registration-restriction';
import { JitsiMeetingRoom } from '@tremaze/shared/feature/jitsi-meet/types';
import { CustomForm } from '@tremaze/shared/feature/custom-forms/types';
import Heap from 'heap';
import { isNotNullOrUndefined } from '@tremaze/shared/util-utilities';
import {
  Approval,
  ApprovalService,
  PartialBudget,
} from '@tremaze/shared/feature/approval/types';
import { EventStatus, isCanceledEventStatus } from './event-status';
import { EventParticipationType } from './event-participation-type';

export type PaymentMethod = 'ON_THE_CLAW' | 'DIGITAL' | 'ALL';

export type TremazeEventSource = 'TREMAZE' | 'OUTLOOK';

export type EventNotificationUnit =
  | 'MINUTE'
  | 'HOUR'
  | 'DAY'
  | 'WEEK'
  | 'MONTH';

export class EventNotification {
  constructor(
    readonly id: string,
    readonly value: number,
    readonly unit: EventNotificationUnit,
  ) {}

  static deserialize(data: any): null | EventNotification {
    return !data ? null : new EventNotification(data.id, data.value, data.unit);
  }
}

@staticImplements<Deserializable<TremazeEvent>>()
export class TremazeEvent extends EventBase {
  constructor(
    id?: string,
    meta?: Meta,
    name?: string,
    address: Address = new Address(),
    description?: string,
    eventCriteria: ParticipationCriteria[] = [],
    registrationNecessary?: boolean,
    minAge = 0,
    maxAge = 0,
    minMember = 0,
    maxMember = 0,
    prices: Price[] = [new Price(0, Currency.EURO)],
    highlight?: boolean,
    workshop?: boolean,
    institutions: Institution[] = [],
    titleImage?: FileStorage,
    confirmationByParent?: boolean,
    parentConfirmationAge = 15,
    categories: Category[] = [],
    departments: Department[] = [],
    public gender: Gender[] = [],
    public holidayEvent?: boolean,
    public startDate?: TremazeDate,
    public endDate?: TremazeDate,
    public published?: boolean,
    public notifications?: EventNotification[],
    public eventFiles: FileStorage[] = [],
    public externalProviderImage?: FileStorage,
    public externalProviderLink?: string,
    public externalProviderName?: string,
    public schedule?: TremazeSchedule,
    public eventSettings: EventSettings = new EventSettings(),
    public users: User[] = [],
    public visibleForFamily?: boolean,
    public creator?: User,
    public organizer?: User,
    public isPublic?: boolean,
    public userTypes: UserType[] = [],
    public customInSchedule = false,
    isAllDay = false,
    public hideWhenFull = false,
    public specializations: Specialization[] = [],
    public contextInstitution?: Institution,
    public temporalRegistrationRestriction?: TemporalRegistrationRestrictionGroup,
    public videoMeeting?: JitsiMeetingRoom,
    documentationForms?: CustomForm[],
    public amountRegistrations?: number,
    public readonly hasDocumentation?: boolean,
    public approval?: Approval,
    public partialBudget?: PartialBudget,
    public eventStatus?: EventStatus,
    billable?: boolean,
    public approvalService?: ApprovalService,
    participationTypes: EventParticipationType[] = [],
    enableParticipationInfo = false,
  ) {
    super(
      id,
      meta,
      name,
      address,
      description,
      eventCriteria,
      registrationNecessary,
      minAge,
      maxAge,
      maxMember,
      prices,
      highlight,
      workshop,
      institutions,
      titleImage,
      confirmationByParent,
      parentConfirmationAge,
      categories,
      departments,
      documentationForms,
      participationTypes,
      enableParticipationInfo,
      billable,
    );
    this._isAllDay = isAllDay;
  }

  private _isAllDay: boolean;

  get isAllDay(): boolean {
    return this._isAllDay;
  }

  set isAllDay(value: boolean) {
    this._isAllDay = value;
  }

  get hasCanceledStatus(): boolean {
    return isCanceledEventStatus(this.eventStatus);
  }

  get clients(): User[] {
    return this.users?.filter((u) => u.isClient) ?? [];
  }

  get employees(): User[] {
    return this.users?.filter((u) => u.isEmployee) ?? [];
  }

  private static _extractUniqueInstIds(users: User[]): string[] {
    const result = new Set<string>();
    for (const user of users) {
      if (user.userInstitutions?.length) {
        for (const { institution } of user.userInstitutions) {
          result.add(institution.id);
        }
      }
    }
    return [...result];
  }

  get userInstIds(): string[] {
    return TremazeEvent._extractUniqueInstIds(this.users);
  }

  get clientsInstIds(): string[] {
    return TremazeEvent._extractUniqueInstIds(this.clients);
  }

  get employeesInstIds(): string[] {
    return TremazeEvent._extractUniqueInstIds(this.employees);
  }

  get userIds(): string[] {
    return this.users?.map((u) => u.id) ?? [];
  }

  get instIdsWithDepartmentsAndUsers(): string[] {
    const { instIds, departmentInstIds, userInstIds } = this;
    return [...new Set([...instIds, ...departmentInstIds, ...userInstIds])];
  }

  /**
   * If any client is part of the context institution, return the context institution id.
   */
  get institutionIdsThatArePartOfClientsAndContextInstitutions(): string[] {
    if (this.contextInstitution) {
      if (
        this.clients.some((c) =>
          c.userInstitutions.some(
            (ui) => ui.institution?.id === this.contextInstitution.id,
          ),
        )
      ) {
        return [this.contextInstitution.id];
      }
    }
    return [];
  }

  /**
   * If any employee is part of the context institution, return the context institution id.
   */
  get institutionIdsThatArePartOfEmployeesAndContextInstitutions(): string[] {
    if (this.contextInstitution) {
      if (
        this.employees.some((e) =>
          e.userInstitutions.some(
            (ui) => ui.institution?.id === this.contextInstitution.id,
          ),
        )
      ) {
        return [this.contextInstitution.id];
      }
    }
    return [];
  }

  /**
   * If any department is part of the context institution, return the context institution id.
   */
  get institutionIdsThatArePartOfDepartmentsAndContextInstitutions(): string[] {
    if (this.contextInstitution) {
      if (
        this.departments.some(
          (d) => d.institution?.id === this.contextInstitution.id,
        )
      ) {
        return [this.contextInstitution.id];
      }
    }
    return [];
  }

  get source(): TremazeEventSource {
    if (this.id?.startsWith('OUTLOOK')) {
      return 'OUTLOOK';
    }
    return 'TREMAZE';
  }

  get color(): string | Color {
    return this.category?.color ?? 'var(--accent)';
  }

  get hasAmountRegistrations(): boolean {
    return isNotNullOrUndefined(this.amountRegistrations);
  }

  get hasMaxMember(): boolean {
    return this.maxMember && this.maxMember > 0;
  }

  get isFull(): boolean {
    return this.hasMaxMember && this.amountRegistrations >= this.maxMember;
  }

  get totalRuntimeInDays(): number {
    if (!this.endDate) {
      return 1;
    }
    return (
      (this.endDate
        .clone()
        ?.startOf('day')
        .diff(this.startDate?.clone().startOf('day'), 'day') || 0) + 1
    );
  }

  get isMultiDay(): boolean {
    return this.totalRuntimeInDays > 1;
  }

  static fromIdAndStartDate(id: string, startDate: TremazeDate) {
    const e = new TremazeEvent(id);
    e.startDate = startDate;
    return e;
  }

  static fromTemplate(template: EventTemplate): null | TremazeEvent {
    const t = { ...(template || {}), id: null, meta: null };
    return this.deserialize(t);
  }

  static deserialize(data: any): null | TremazeEvent {
    return !data
      ? null
      : new TremazeEvent(
          data.id,
          Meta.deserialize(data),
          data.name,
          Address.deserialize(data.address),
          data.description,
          data.eventCriteria?.map(ParticipationCriteria.deserialize) || [],
          data.registrationNecessary,
          data.minAge,
          data.maxAge,
          data.minMember,
          data.maxMember,
          TremazeEvent.deserializePrices(data.prices, data.price),
          data.highlight,
          data.workshop,
          data.institutions?.map(Institution.deserialize) || [],
          FileStorage.deserialize(data.titleImage),
          data.confirmationByParent,
          data.parentConfirmationAge,
          data.categories?.map(Category.deserialize) ?? [],
          data.departments?.map(Department.deserialize),
          data.gender?.map(Gender.deserialize) || [],
          data.holidayEvent,
          TremazeDate.deserialize(data.startDate),
          TremazeDate.deserialize(data.endDate),
          data.published,
          TremazeEvent.deserializeNotifications(data.notifications),
          data.eventFiles?.map(FileStorage.deserialize) || [],
          FileStorage.deserialize(data.externalProviderImage),
          data.externalProviderLink,
          data.externalProviderName,
          TremazeSchedule.deserialize(data.schedule),
          EventSettings.deserialize(data.eventSettings) ?? new EventSettings(),
          data.users?.map(User.deserialize) || [],
          data.visibleForFamily,
          User.deserialize(data.creator),
          User.deserialize(data.organizer),
          data.public ?? data.isPublic,
          data.userTypes,
          data.customInSchedule,
          data.allDay ?? data.isAllDay,
          data.hideWhenFull,
          data.specializations?.map(Specialization.deserialize) || [],
          Institution.deserialize(data.contextInstitution),
          TemporalRegistrationRestrictionGroup.deserialize(
            data.temporalRegistrationRestriction,
          ),
          JitsiMeetingRoom.deserialize(data.videoMeeting),
          data.documentationForms?.map(CustomForm.deserialize) || [],
          data.amountRegistrations,
          data.hasDocumentation,
          data.approval ? Approval.deserialize(data.approval) : null,
          data.partialBudget
            ? PartialBudget.deserialize(data.partialBudget)
            : null,
          data.eventStatus,
          data.billable,
          data.approvalService,
          data.participationTypes ?? [],
          data.enableParticipationInfo,
        );
  }

  static deserializePrices(
    pricesListData?: any[],
    pricesValueData?: number,
  ): Price[] {
    if (pricesListData) {
      return Price.deserializeList(pricesListData);
    } else {
      return [Price.createEuroPrice(pricesValueData)];
    }
  }

  static deserializeNotifications(notifications?: any[]): EventNotification[] {
    return !notifications
      ? null
      : notifications.map(EventNotification.deserialize);
  }

  clone() {
    return TremazeEvent.deserialize(this) as TremazeEvent;
  }

  private _calculateRestrictionDate(
    restriction?: TemporalRegistrationRestriction,
  ) {
    if (!restriction) {
      return null;
    }
    const durationInMinutes = restriction.duration.inMinutes;
    const startDate = this.startDate;
    if (startDate) {
      if (restriction.relation === 'BEFORE') {
        return startDate.clone().subtract(durationInMinutes, 'minute');
      }
      return startDate.clone().add(durationInMinutes, 'minute');
    }
    return null;
  }

  get signOnDate(): TremazeDate | null {
    return this._calculateRestrictionDate(
      this.temporalRegistrationRestriction?.register,
    );
  }

  get signOffDate(): TremazeDate | null {
    return this._calculateRestrictionDate(
      this.temporalRegistrationRestriction?.cancel,
    );
  }

  copyWithEmptyId(): TremazeEvent {
    return TremazeEvent.deserialize({ ...this, id: null, meta: null });
  }

  toTemplate(): EventTemplate {
    return EventTemplate.deserialize({ ...this, id: null, meta: null });
  }

  dayFallsWithinEvent(day: TremazeDate): boolean {
    if (!this.endDate) {
      return day.isSame(this.startDate, 'day');
    }
    return day.isSameOrBetween(this.startDate, this.endDate, 'day');
  }

  isAllDayForDay(day: TremazeDate): boolean {
    if (this.isAllDay) {
      return true;
    }
    if (
      (this.startDate.isSameDay(day) && this.startDate.isMidnight) ||
      this.startDate.isBefore(day, 'day')
    ) {
      return (
        this.endDate != null &&
        ((this.endDate.isSameDay(day) && this.endDate.isMidnight) ||
          this.endDate.isAfter(day, 'day'))
      );
    }
    return false;
  }
}

export function mergeSortedEventArrays(
  ...arrays: TremazeEvent[][]
): TremazeEvent[] {
  const heap = new Heap<TremazeEvent>((a, b) =>
    a.startDate.isBefore(b.startDate) ? -1 : 1,
  );

  const ids = new Set<string>();

  for (const array of arrays) {
    for (const event of array) {
      if (ids.has(event.id)) {
        continue;
      }
      ids.add(event.id);
      heap.push(event);
    }
  }

  return heap.toArray();
}
