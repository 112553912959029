<div class="selected-fd">
  <div class="selected-fd__head">Mehrfachaktionen</div>
  <mat-divider></mat-divider>
  <div class="selected-fd__content">
    <h5 class="selected-fd__content__headline">Ordner</h5>
    <mat-list
      class="selected-fd__content__list selected-fd__content__list--folders"
    >
      <mat-list-item
        *ngFor="let folder of folders"
        class="selected-fd__content__list__item"
      >
        <span
          [matTooltip]="folder.tenantDir ? 'Träger-Ordner' : 'Normaler Ordner'"
          [class]="folder.tenantDir ? 'lnr lnr-folder-star' : 'lnr lnr-folder'"
        ></span>
        <span>{{ folder.dirViewname }}</span>
        <span class="spacer"></span>
        <button
          mat-icon-button
          color="accent"
          (click)="onClickDownloadFolder(folder)"
          *ngIf="false"
          [matTooltip]="folder.dirname + ' herunterladen'"
        >
          <span class="lnr lnr-download"></span>
        </button>
        <button
          *ngIf="hasDeletePermission(folder) | async"
          mat-icon-button
          color="warn"
          (click)="onClickDeleteFolder(folder)"
          [matTooltip]="folder.dirViewname + ' löschen'"
        >
          <span class="lnr lnr-trash2"></span>
        </button>
      </mat-list-item>
    </mat-list>
    <h5 class="selected-fd__content__headline">Dateien</h5>
    <mat-list
      class="selected-fd__content__list selected-fd__content__list--files"
    >
      <mat-list-item
        *ngFor="let file of files"
        class="selected-fd__content__list__item"
      >
        <span
          [class]="
            file.type === 'IMAGE' ? 'lnr lnr-file-image' : 'lnr lnr-file-empty'
          "
        ></span>
        <span>{{ file.fileViewname }}</span>
        <span class="spacer"></span>
        <button
          mat-icon-button
          color="accent"
          (click)="onClickDownloadFile(file)"
          [matTooltip]="file.fileViewname + ' herunterladen'"
        >
          <span class="lnr lnr-download"></span>
        </button>
        <button
          mat-icon-button
          color="warn"
          *ngIf="hasDeletePermission(file) | async"
          (click)="onClickDeleteFile(file)"
          [matTooltip]="file.fileViewname + ' löschen'"
        >
          <span class="lnr lnr-trash2"></span>
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>

      <div class="selected-fd__actions">
        <button
          mat-flat-button
          color="accent"
          (click)="onClickDownloadAll()"
          hidden
          *ngIf="false"
        >
          Alles herunterladen
        </button>
        <button
          mat-flat-button
          color="warn"
          (click)="onClickDeleteAll()"
          *tremazeHasPermission="deletePermissionRequest"
        >
          Alles löschen
        </button>
      </div>
    </mat-list>
  </div>
</div>
