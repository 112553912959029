import { Inject, Injectable, Optional } from '@angular/core';
import { TremazeDate } from '@tremaze/shared/util-date';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { EventStatus, TremazeEvent } from '@tremaze/shared/feature/event/types';
import { catchError, from, Observable, of, pluck, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import {
  EVENT_MODULE_CONFIG,
  EventModuleConfig,
} from '@tremaze/shared/feature/event/module-config';
import { InstitutionContextService } from '@tremaze/shared/feature/institution/shared/singletons';
import { map } from 'rxjs/operators';
import { MSALAuthService } from '@tremaze/shared/core/msal';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { Address, tryGetCountryByCode } from '@tremaze/shared/models';
import {
  EventDateRange,
  RemoteEventCRUDDataSourceDefaultImpl,
} from '@tremaze/shared/feature/event/data-access';
import * as Sentry from '@sentry/angular-ivy';

function mapMicrosoftLocationToAddress(
  location: MicrosoftGraph.Location,
): Address | null {
  if (!location?.address) {
    return null;
  }

  return new Address(
    tryGetCountryByCode(location.address.countryOrRegion),
    location.address.city,
    location.address.postalCode,
    location.address.street,
  );
}

function mapMicrosoftGraphEventToTremazeEvent(
  event: MicrosoftGraph.Event,
): TremazeEvent {
  return new TremazeEvent(
    'OUTLOOK_' + event.id,
    null,
    event.subject,
    mapMicrosoftLocationToAddress(event.location),
    event.body?.content,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    false,
    [],
    null,
    false,
    null,
    null,
    [],
    null,
    false,
    new TremazeDate(event.start.dateTime),
    new TremazeDate(event.end.dateTime),
    true,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    event.isAllDay,
    null,
    null,
    null,
    null,
    null,
  );
}

@Injectable({ providedIn: 'root' })
export class RemoteEwsEventDataSource extends RemoteEventCRUDDataSourceDefaultImpl {
  constructor(
    http: HttpClient,
    js: JsonSerializer,
    private msal: MSALAuthService,
    @Optional()
    @Inject(EVENT_MODULE_CONFIG)
    moduleConfig?: EventModuleConfig,
    @Optional()
    institutionContextService?: InstitutionContextService,
  ) {
    super(http, js, moduleConfig, institutionContextService);
  }

  getEventsInDateRange(
    dateRange: EventDateRange,
    types,
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    instIds?: string[],
    departmentIds?: string[],
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]> {
    const requests = [
      super.getEventsInDateRange(
        dateRange,
        types,
        onlyHolidayEvents,
        options,
        instIds,
        departmentIds,
        userIds,
        maxResults,
        status,
      ),
    ];
    if (this.msal.isLoggedIn) {
      requests.push(this._getEwsEventsInDateRange(dateRange));
    } else {
      requests.push(of([]));
    }
    return zip(requests).pipe(
      map((r) =>
        [...r[0], ...r[1]].sort(
          (a, b) => a.startDate.getTime() - b.startDate.getTime(),
        ),
      ),
    );
  }

  private _getEwsEventsInDateRange(
    dateRange: EventDateRange,
  ): Observable<TremazeEvent[]> {
    return from(
      this.msal
        .getGraphClient()
        .api('/me/calendarview')
        .header('Prefer', 'outlook.timezone="Europe/Berlin"')
        .query({
          startDateTime: dateRange[0].toISOString(),
          endDateTime: dateRange[1]?.toISOString(),
        })
        .select('subject,start,end,location,isAllDay,organizer,attendees')
        .orderby('start/dateTime')
        .get(),
    ).pipe(
      pluck('value'),
      map((r: MicrosoftGraph.Event[]) =>
        r.map(mapMicrosoftGraphEventToTremazeEvent),
      ),
      catchError((err) => {
        console.error(err);
        Sentry.captureException(err);
        return of([]);
      }),
    );
  }
}
