import { Observable } from 'rxjs';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Pagination } from '@tremaze/shared/models';

export abstract class DataTableDataSource<T> {
  abstract getPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<T>>;
  abstract deleteById(id: string): Observable<boolean>;
}
