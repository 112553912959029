import { inject, Injectable } from '@angular/core';
import { FileStorageDataSource } from '../file-storage-data-source';
import {
  FileStorage,
  FileStorageEntityMeta,
  FileStorageEntityType,
} from '@tremaze/shared/feature/file-storage/types';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { filterResponse, reportProgressPercent } from '../helpers';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';

@Injectable()
export class FileStorageDataSourceImpl implements FileStorageDataSource {
  private readonly _http = inject(HttpClient);

  downloadFile(p: {
    fileId: string;
    fleType?: string | undefined;
    onProgress?: ((progressPercent: number) => void) | undefined;
  }): Observable<Blob> {
    return this._http
      .request<Blob>(
        new HttpRequest('GET', `public/dir/files/download/${p.fileId}`, {
          reportProgress: true,
          responseType: 'blob',
        }),
      )
      .pipe(
        reportProgressPercent(p.onProgress),
        filterResponse(),
        map((event) => event.body),
        map((blob) => {
          if (!blob) {
            throw new Error('File not found');
          }
          return blob;
        }),
      );
  }

  deletesFiles(...ids: string[]): Observable<void> {
    if (ids.length === 0) {
      return of();
    }
    return this._http.delete<void>(`dirs/files`, {
      params: { fileIds: ids.join(',') },
    });
  }

  uploadAvatar(p: {
    file: File;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage> {
    const uploadData = new FormData();
    uploadData.append('file', p.file);

    return this._http
      .request<TremazeHttpResponse<Record<string, unknown>>>(
        new HttpRequest('POST', `files/upload/avatar`, uploadData, {
          reportProgress: true,
          params: new HttpParams({ fromObject: { 'ngsw-bypass': '' } }),
        }),
      )
      .pipe(
        reportProgressPercent(p.onProgress),
        filterResponse(),
        map((r) => r.body),
        map((body) => {
          if (!body) {
            throw new Error('Failed to upload avatar');
          }
          return FileStorage.deserialize(body.object);
        }),
      );
  }

  overwriteFile(p: {
    fileId: string;
    file: File;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage> {
    const uploadData = new FormData();
    uploadData.append('file', p.file);

    return this._http
      .request<TremazeHttpResponse<FileStorage>>(
        new HttpRequest('POST', `files/${p.fileId}/override`, uploadData, {
          reportProgress: true,
          params: new HttpParams({ fromObject: { 'ngsw-bypass': '' } }),
        }),
      )
      .pipe(
        reportProgressPercent(p.onProgress),
        filterResponse(),
        map((r) => r.body),
        map((body) => {
          if (!body) {
            throw new Error('Failed to overwrite file');
          }
          return FileStorage.deserialize(body.object);
        }),
      );
  }

  uploadEntityFiles(p: {
    files: File[];
    entity: FileStorageEntityType;
    entityMeta?: FileStorageEntityMeta | undefined;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage[]> {
    const uploadData = new FormData();
    for (const file of p.files) {
      uploadData.append('files', file);
    }
    return this._http
      .request<TremazeHttpResponse<FileStorage[]>>(
        new HttpRequest('POST', `entityFiles/upload`, uploadData, {
          params: new HttpParams({
            fromObject: {
              'ngsw-bypass': 'null',
              entity: p.entity,
              ...(p.entityMeta ?? {}),
            },
          }),
          reportProgress: true,
        }),
      )
      .pipe(
        reportProgressPercent(p.onProgress),
        filterResponse(),
        map((r) => r.body),
        map((body) => {
          if (!body) {
            throw new Error('Failed to upload files');
          }
          return body.object.map((f) => FileStorage.deserialize(f));
        }),
      );
  }

  updateFileName(p: {
    fileId: string;
    newName: string;
  }): Observable<FileStorage> {
    return this._http
      .put<TremazeHttpResponse<FileStorage>>(`files/${p.fileId}/rename`, null, {
        params: { newName: p.newName },
      })
      .pipe(
        map((response) => {
          if (response.status !== 'SUCCESS') {
            throw new Error('Failed to update file name');
          }
          return FileStorage.deserialize(response.object);
        }),
      );
  }
}

export const provideFileStorageDataSource = () => ({
  provide: FileStorageDataSource,
  useClass: FileStorageDataSourceImpl,
});
