@if (!pdfTemplateAvailable) {
  <div>Derzeit ist keine PDF-Berichtsvorlage hinterlegt.</div>
} @else {
  <div>
    <div>
      <button
        [disabled]="!pdfPreviewUrl$"
        mat-button
        (click)="openHtmlPreview()"
      >
        PDF-Vorschau ansehen
      </button>
    </div>

    <div
      *ngIf="generatingPDFReport$ | async; else buttons"
      style="
        margin-top: 16px;
        height: 90px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        "
      >
        <mat-progress-spinner
          mode="indeterminate"
          diameter="25"
        ></mat-progress-spinner>
        <div style="font-size: 12px; text-align: center; margin-top: 8px">
          PDF-Bericht wird erstellt...
        </div>
      </div>
    </div>

    <ng-template #buttons>
      <div
        *ngIf="availablePDFReport$ | async; else noReport"
        class="pdf-button"
        style="
          width: fit-content;
          padding: 8px;
          cursor: pointer;
          margin-top: 16px;
        "
      >
        <div
          (click)="openPdfViewer()"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <tremaze-report-available-svg />
          <div style="font-size: 12px">PDF-Bericht ansehen</div>
        </div>
      </div>
      <ng-template #noReport>
        <div
          class="pdf-button"
          style="
            width: fit-content;
            padding: 8px;
            cursor: pointer;
            margin-top: 16px;
          "
        >
          <div
            (click)="emitGeneratePDFReport()"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <tremaze-no-report-svg />
            <div style="font-size: 12px">PDF-Bericht erstellen</div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
}

@if (!csvTemplateAvailable) {
  <div>Derzeit ist keine CSV-Berichtsvorlage hinterlegt.</div>
} @else {
  <div>
    <div
      *ngIf="generatingCSVReport$ | async; else buttons"
      style="
        margin-top: 16px;
        height: 90px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        "
      >
        <mat-progress-spinner
          mode="indeterminate"
          diameter="25"
        ></mat-progress-spinner>
        <div style="font-size: 12px; text-align: center; margin-top: 8px">
          CSV-Bericht wird erstellt...
        </div>
      </div>
    </div>

    <ng-template #buttons>
      <div
        *ngIf="availableCSVReport$ | async; else noReport"
        class="pdf-button"
        style="
          width: fit-content;
          padding: 8px;
          cursor: pointer;
          margin-top: 16px;
        "
      >
        <div
          (click)="downloadCSVExport()"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <tremaze-report-available-svg />
          <div style="font-size: 12px">CSV-Bericht herunterladen</div>
        </div>
      </div>
      <ng-template #noReport>
        <div
          class="pdf-button"
          style="
            width: fit-content;
            padding: 8px;
            cursor: pointer;
            margin-top: 16px;
          "
        >
          <div
            (click)="emitGenerateCSVReport()"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <tremaze-no-report-svg />
            <div style="font-size: 12px">CSV-Bericht erstellen</div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
}
