import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Equatable, Meta } from '@tremaze/shared/models';

@staticImplements<Deserializable<Department>>()
export class Department implements Equatable {
  constructor(
    readonly id: string,
    readonly meta?: Meta,
    public name?: string,
    readonly institution?: { id: string },
  ) {}

  static deserialize(data: any): Department | null {
    return !data
      ? null
      : new Department(
          data.id,
          Meta.deserialize(data?.meta),
          data.name,
          data.institution,
        );
  }

  equals(other: Department): boolean {
    return this.id === other.id;
  }
}
