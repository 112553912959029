import { Injectable } from '@angular/core';
import { FileStoragePermissionsService } from './file-storage-permissions.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class FileStoragePermissionMockService extends FileStoragePermissionsService {
  override getPermissionsForDir(id: string): Observable<string[]> {
    return of(['READ', 'WRITE', 'DELETE']);
  }
}

export const provideMockFileStoragePermissionService = () => ({
  provide: FileStoragePermissionsService,
  useClass: FileStoragePermissionMockService,
});
