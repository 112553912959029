@if (folder) {
  <div
    class="folder-view-folders-and-files__Head"
    [class.folder-view-folders-and-files__Head--Scrolled]="isScrolled"
  >
    <tremaze-folder-view-breadcrumbs
      [path]="folder.absoluteViewPath ?? folder.absolutePath"
      (navigate)="onClickBreadcrumb($event)"
      (dataDropped)="onDataDropped($event.data, $event.path)"
      (filesDropped)="onFilesDropped($event.files, $event.path)"
    ></tremaze-folder-view-breadcrumbs>

    <tremaze-expanding-icon-text-input
      [value]="searchValue"
      (valueChange)="searchValueChange.emit($event)"
    ></tremaze-expanding-icon-text-input>
  </div>
  <div class="folder-view-folders-and-files__Body">
    <h4>Ordner</h4>
    @if (folders?.length || showCreateFolder) {
      <tremaze-folder-view-folders @list>
        @for (folder of folders; track folder.id) {
          <button
            tremaze-folder-view-folder-item
            tremazeSelectZoneItem
            tremazeDragZoneItem
            tremazeDropZone
            tremazeFileDropInDropZone
            [dragData]="folder"
            [dragDisabled]="!isFolderSelected(folder)"
            [selectionData]="folder"
            [selected]="isFolderSelected(folder)"
            [folder]="folder"
            [canWrite$]="getCanWriteStreamForFileOrFolder?.(folder)"
            [dropZoneDisabled]="
              (getCanWriteStreamForFileOrFolder?.(folder) | async) !== true
            "
            (filesDropped)="onFilesDropped($event, folder.absolutePath)"
            (dropped)="onDataDropped($event, folder.absolutePath)"
            (click)="onClickFolder(folder, $event)"
            (dblclick)="onDoubleClickFolder(folder)"
            (openFolder)="openFolder.emit(folder)"
            (deleteFolder)="deleteFolder.emit(folder)"
            (submitFolderName)="onSubmitFolderName($event, folder.id)"
            [selectionDisabled]="!folder.canBeTinkeredWith"
          ></button>
        }

        @if (showCreateFolder) {
          <tremaze-folder-view-folder-item
            isCreateFolderItem
            (submitFolderName)="onSubmitFolderName($event)"
            (cancelCreateFolder)="onCancelCreateFolder()"
          ></tremaze-folder-view-folder-item>
        }
      </tremaze-folder-view-folders>
    }
    <h4>Dateien</h4>
    <div
      class="folder-view-folders-and-files__Files-Wrapper"
      tremazeFileDropInDropZone
      #currentDirDropZone="fileDropInDropZone"
      (filesDropped)="onFilesDropped($event)"
    >
      @if (files?.length) {
        <tremaze-folder-view-files @list style="padding-bottom: 80px">
          @for (file of files; track file.id) {
            <figure
              tremaze-folder-view-file-item
              tremazeSelectZoneItem
              tremazeDragZoneItem
              tabindex="0"
              [dragData]="file"
              [dragDisabled]="!isFileSelected(file)"
              [selectionData]="file"
              [file]="file"
              [selected]="isFileSelected(file)"
              [disabled]="isFileDisabled(file)"
              [canWrite$]="getCanWriteStreamForFileOrFolder?.(file)"
              (click)="onClickFile(file, $event)"
              (dblclick)="onDoubleClickFile(file)"
              (showFilePreview)="showFilePreview.emit(file)"
              (deleteFile)="deleteFile.emit(file)"
              (copyFileLink)="copyFileLink.emit(file)"
              (downloadFile)="downloadFile.emit(file)"
              (navigateToPath)="navigate.emit(file.absolutePath)"
              (submitFileName)="
                submitFileName.emit({
                  fileId: file.id,
                  name: $event,
                })
              "
            ></figure>
          }
        </tremaze-folder-view-files>
      }
      @else if (isLoadingFiles) {
        <div class="center-children" style="padding: 50px">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
      } @else {
        <div class="center-children" style="padding-top: 100px">
          <p>Keine Dateien vorhanden</p>
        </div>
      }
      @if (currentDirDropZone.isDraggingFiles) {
        <div class="folder-view-folders-and-files__Files-Wrapper__Drop-Zone">
          <div>
            <p>Dateien hier hin ziehen</p>
            <tremaze-icon icon="lnr-download2"></tremaze-icon>
          </div>
        </div>
      }
    </div>
  </div>
}

<tremaze-drag-zone-preview>
  <tremaze-folder-view-drag-preview
    [files]="selectedFiles"
    [folders]="selectedFolders"
  ></tremaze-folder-view-drag-preview>
</tremaze-drag-zone-preview>
