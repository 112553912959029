import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { Observable } from 'rxjs';
import { SelectedFilesAndFolderContextmenuResponse } from '@tremaze/shared/feature/file-storage/ui/selected-files-and-folders-contextmenu/types';
import { SelectedFilesAndFoldersContextmenuComponent } from './selected-files-and-folders-contextmenu.component';

@Injectable({
  providedIn: 'root',
})
export class SelectedFilesAndFoldersContextmenuService {
  constructor(private _bottomSheet: MatBottomSheet) {}

  public showContextMenu(data: {
    selectedFiles: FileStorage[];
    selectedFolders: DirStorage[];
    currentFolder: DirStorage;
  }): Observable<SelectedFilesAndFolderContextmenuResponse | null> {
    return this._bottomSheet
      .open(SelectedFilesAndFoldersContextmenuComponent, { data })
      .afterDismissed();
  }
}
