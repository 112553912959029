<div class="file-selector-dialog-wrapper">
  <tremaze-folder-view
    disableRouting
    singleSelection
    (confirmSelection)="onFileSelected($event)"
    [selectionRegex]="data?.fileTypeMatcher"
  >
    <button mat-button [matDialogClose]="undefined" slot="actions-start">
      Schließen
    </button>
  </tremaze-folder-view>
</div>
