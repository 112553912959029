import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Report } from '../../domain/report.type';
import { HtmlPreviewService } from '../html-preview/html-preview.service';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, takeUntil } from 'rxjs';
import { ReportRepository } from '../../domain/report.repository';
import { ReportGeneratorService } from '../report-generator.service';
import { PdfViewerService } from '@tremaze/shared/feature/pdf-viewer';
import { NotificationService } from '@tremaze/shared/notification';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * HOW TO USE THIS COMPONENT:
 *
 * You have to pass the formSubmissionId to the component as a input.
 * Additionally, you have to provide a implementation of the ReportGeneratorService.
 * It is implemented differently for EventDocumentation and UserForms for example.
 */

@Component({
  selector: 'tremaze-report-block',
  templateUrl: './report-block.component.html',
  styleUrl: './report-block.component.scss',
})
export class ReportBlockComponent implements OnInit, OnDestroy {
  @Input() set pdfReport(report: Report) {
    this.availablePDFReportSubject$.next(report);
    this.generatingPDFReportSubject.next(false);
  }

  @Input() set csvReport(report: Report) {
    this.availableCSVReportSubject$.next(report);
    this.generatingCSVReportSubject.next(false);
  }

  @Input() formSubmissionId?: string;

  @Input() pdfTemplateAvailable = false;
  @Input() pdfReportName?: string;

  @Input() csvTemplateAvailable = false;
  @Input() csvReportName?: string;

  generatingPDFReportSubject = new BehaviorSubject<boolean>(false);
  generatingPDFReport$ = this.generatingPDFReportSubject.asObservable();

  availablePDFReportSubject$ = new ReplaySubject<Report>(1);
  availablePDFReport$ = this.availablePDFReportSubject$.asObservable();

  pdfPreviewUrl$?: Observable<string>;

  generatingCSVReportSubject = new BehaviorSubject<boolean>(false);
  generatingCSVReport$ = this.generatingCSVReportSubject.asObservable();

  availableCSVReportSubject$ = new ReplaySubject<Report>(1);
  availableCSVReport$ = this.availableCSVReportSubject$.asObservable();

  constructor(
    private htmlPreviewService: HtmlPreviewService,
    private pdfViewerService: PdfViewerService,
    private reportRepository: ReportRepository,
    private reportGeneratorService: ReportGeneratorService,
    private readonly _notificationService: NotificationService,
  ) {}

  ngOnInit() {
    if (!this.formSubmissionId) {
      console.warn('formSubmissionId is required in ReportBlockComponent');
      return;
    }
    this.pdfPreviewUrl$ = this.reportGeneratorService.getPreviewUrl(
      this.formSubmissionId,
    );
  }

  ngOnDestroy() {
    this.generatingPDFReportSubject.complete();
    this.availablePDFReportSubject$.complete();
    this.availableCSVReportSubject$.complete();
    this.generatingCSVReportSubject.complete();
  }

  async emitGeneratePDFReport() {
    if (!this.formSubmissionId) {
      this._notificationService.showNotification(
        'Bitte speichere das Formular bevor du den Bericht generierst.',
      );
      return;
    }
    this.generatingPDFReportSubject.next(true);
    try {
      const report = await firstValueFrom(
        this.reportGeneratorService.generateReport(
          this.formSubmissionId,
          'PDF',
        ),
      );
      this.availablePDFReportSubject$.next(report);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        this._notificationService.showNotification(
          'Fehler beim Laden des Berichts. Möglicherweise wurde kein HTML-Template hinterlegt.',
        );
        return;
      }
      this._notificationService.showNotification(
        'Fehler beim Generieren des Berichts.',
      );
    } finally {
      this.generatingPDFReportSubject.next(false);
    }
  }

  async openHtmlPreview() {
    if (!this.pdfPreviewUrl$) {
      return;
    }
    if (!this.formSubmissionId) {
      throw new Error('formSubmissionId is required in ReportBlockComponent');
    }
    // The Observable has to be called from the reportGeneratorService, not from previewUrl$ because of token refreshment!
    const previewUrl = await firstValueFrom(
      this.reportGeneratorService.getPreviewUrl(this.formSubmissionId),
    );
    this.htmlPreviewService.openHtmlPreview(previewUrl);
  }

  async openPdfViewer() {
    const report = await firstValueFrom(this.availablePDFReport$);
    if (!report) {
      return;
    }
    const pdfUrl = await firstValueFrom(
      this.reportRepository.getPDFReportUrl(report.id),
    );
    const ref = this.pdfViewerService.openPDF({
      pdfUrl,
      pdfName: this.pdfReportName || 'report.pdf',
    });
    const instance = ref.componentRef?.instance;
    if (instance) {
      ref.componentRef.instance.save
        .pipe(takeUntil(ref.afterClosed()))
        .subscribe((r) => {
          if (!report.file) {
            return;
          }
          const file = new File([r], this.pdfReportName || 'report.pdf', {
            type: 'application/pdf',
          });
          this.reportRepository
            .overwriteReport(report.file.id, file)
            .subscribe();
        });
    }
  }

  emitGenerateCSVReport() {
    if (!this.formSubmissionId) {
      this._notificationService.showNotification(
        'Bitte speichere das Formular bevor du den Bericht generierst.',
      );
      return;
    }
    this.generatingCSVReportSubject.next(true);
    this.reportGeneratorService
      .generateReport(this.formSubmissionId, 'CSV')
      .subscribe(
        (report) => {
          this.availableCSVReportSubject$.next(report);
          this.generatingCSVReportSubject.next(false);
        },
        () => {
          this._notificationService.showNotification(
            'Fehler beim Generieren des Berichts.',
          );
          this.generatingCSVReportSubject.next(false);
        },
      );
  }

  async downloadCSVExport() {
    const report = await firstValueFrom(this.availableCSVReport$);
    if (!report) {
      return;
    }
    this.reportRepository.getCSVReportUrl(report.id).subscribe((url) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.pdfReportName || 'report'}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      a.remove();
    });
  }
}
