import {
  AbstractControl as NGAbstractControl,
  AsyncValidatorFn,
  FormArray,
  ValidatorFn,
} from '@angular/forms';
import {
  AbstractControl,
  FormControl,
  FormGroup,
} from '@ngneat/reactive-forms';
import { RemoteDuplicateCheckDataSource } from '@tremaze/shared/feature/duplicate-check/data-access';
import { of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { isEmpty, isNotEmpty } from '@tremaze/shared/util-utilities';
import { TremazeDate } from '@tremaze/shared/util-date';

export const zipPattern = '^([0-9]){5}$';
export const urlPattern =
  '^(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})$';
export const usernamePattern = '^([A-Za-z0-9])+$';
export const legalNamePattern =
  "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$";
export const legalNameWithNumbersPattern =
  "^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$";

interface ComparingValidatorConfig {
  fromOptional?: boolean;
  toOptional?: boolean;
  isEmptyFn?: (v: unknown) => boolean;
}

export abstract class TremazeValidators {
  static username(control: AbstractControl): { [key: string]: any } | null {
    const r = new RegExp(usernamePattern);
    const value = control.value?.trim();
    const forbidden = value?.length && !r.test(value);
    return forbidden ? { username: { value } } : null;
  }

  static legalName(config?: { allowNumbers: boolean }): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const r = config?.allowNumbers
        ? new RegExp(legalNameWithNumbersPattern)
        : new RegExp(legalNamePattern);
      const value = control.value?.trim();
      const forbidden = value?.length && !r.test(value);
      return forbidden ? { legalName: { value } } : null;
    };
  }

  static url(control: AbstractControl): { [key: string]: any } | null {
    const r = new RegExp(urlPattern);
    const forbidden = control.value && !r.test(control.value);
    return forbidden ? { url: { value: control.value } } : null;
  }

  static zip(control: AbstractControl): { [key: string]: any } | null {
    const r = new RegExp(zipPattern);
    const forbidden = control.value && !r.test(control.value);
    return forbidden ? { zip: { value: control.value } } : null;
  }

  static phone(control: AbstractControl): { [key: string]: any } | null {
    const r = /^(\+|0)(?:[0-9] ?){6,14}[0-9]$/;
    const forbidden = control.value && !r.test(control.value);
    return forbidden ? { phone: { value: control.value } } : null;
  }

  static number(control: AbstractControl): { [key: string]: any } | null {
    const forbidden = isNaN(control.value);
    return forbidden ? { number: { value: control.value } } : null;
  }

  static minLengthArray(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden =
        !Array.isArray(control.value) || minLength > control.value.length;
      return forbidden ? { minLengthArray: { value: control.value } } : null;
    };
  }

  static maxLengthArray(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden =
        Array.isArray(control.value) && maxLength < control.value.length;
      return forbidden ? { maxLengthArray: { value: control.value } } : null;
    };
  }

  static idInArray(idArr: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const id = value?.id || value;
      return !idArr.includes(id)
        ? { idInArray: { value: control.value } }
        : null;
    };
  }

  static minAge(minAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;
      const age = TremazeValidators.getAgeFromControlValue(value);
      const forbidden = !(typeof age === 'number') || age < minAge;
      return forbidden ? { minAge: { value: control.value } } : null;
    };
  }

  static maxAge(minAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;
      const age = TremazeValidators.getAgeFromControlValue(value);
      const forbidden = !(typeof age === 'number') || age > minAge;
      return forbidden ? { maxAge: { value: control.value } } : null;
    };
  }

  static arrayContainsObjectWithTrueProperty<T>(
    propertyName: keyof T,
  ): ValidatorFn {
    return (control: AbstractControl<T[]>): { [key: string]: any } | null => {
      const { value } = control;
      const forbidden = !value.some((o) => !!o[propertyName]);
      return forbidden
        ? { arrayContainsObjectWithTrueProperty: { value: control.value } }
        : null;
    };
  }

  static emailAvailability(
    dataSource: RemoteDuplicateCheckDataSource,
    initialValue?: string,
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (
        !control ||
        !control.value ||
        !control.value.length ||
        control.value === initialValue
      ) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(() =>
          dataSource.checkForEmailDuplicate(control.value).pipe(
            map((r) => {
              if (!r) {
                return null;
              }
              return { emailInUse: true };
            }),
          ),
        ),
      );
    };
  }

  static usernameAvailability(
    dataSource: RemoteDuplicateCheckDataSource,
    initialValue?: string,
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (
        !control ||
        !control.value ||
        !control.value.length ||
        control.value === initialValue
      ) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(() =>
          dataSource.checkForUsernameDuplicate(control.value).pipe(
            map((r) => {
              if (!r) {
                return null;
              }
              return { usernameInUse: true };
            }),
          ),
        ),
      );
    };
  }

  static mobileAvailability(
    dataSource: RemoteDuplicateCheckDataSource,
    initialValue?: string,
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (
        !control ||
        !control.value ||
        !control.value.length ||
        control.value === initialValue
      ) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(() =>
          dataSource.checkForMobileDuplicate(control.value).pipe(
            map((r) => {
              if (!r) {
                return null;
              }
              return { mobileInUse: true };
            }),
          ),
        ),
      );
    };
  }

  static equalValueValidator<
    T extends {
      [K in keyof T]: AbstractControl<any> | T[K];
    },
  >(targetKey: keyof T, toMatchKey: keyof T): ValidatorFn {
    return (group: FormGroup<T>): { [key: string]: any } => {
      // TODO: fix the types so that we dont have to cast to any
      const target = group.controls[targetKey as any];
      const toMatch = group.controls[toMatchKey as any];
      if (target?.touched && toMatch?.touched) {
        const isMatch = target.value === toMatch.value;
        if (!isMatch) {
          this._addErrorToControl(target, 'equalValue', targetKey);
          const message = targetKey.toString() + ' != ' + toMatchKey.toString();
          return { equalValue: message };
        }
        if (isMatch) {
          this._removeErrorFromControl(target, 'equalValue');
        }
      }

      return null;
    };
  }

  static eitherRequiredValidator<T>(...keys: (keyof T)[]): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      // TODO: fix the types so that we dont have to cast to any
      const controls: FormControl[] = keys.map(
        (key) => group.controls[key as any],
      ) as FormControl[];
      const atLeastOneSet = controls.some((control) =>
        isNotEmpty(control.value),
      );
      // set equal value error on dirty controls
      if (!atLeastOneSet) {
        for (let i = 0; i < controls.length; i++) {
          this._addErrorToControl(controls[i], 'eitherRequired', keys[i]);
        }
        const message = `Either ${keys.join(', ')} needs to be set`;
        return { eitherRequired: message };
      } else {
        for (const control of controls) {
          this._removeErrorFromControl(control, 'eitherRequired');
        }
      }

      return null;
    };
  }

  private static _comparingValidator<T>(
    from: keyof T,
    to: keyof T,
    comparator: (from, to) => boolean,
    name: string,
    message: string,
    config?: ComparingValidatorConfig,
  ): ValidatorFn {
    const isEmptyFn = config?.isEmptyFn ?? isEmpty;
    const actualComparator = (from, to) => {
      if (config?.fromOptional && isEmptyFn(from)) {
        return true;
      }
      if (config?.toOptional && isEmptyFn(to)) {
        return true;
      }
      return comparator(from, to);
    };

    return (group: FormGroup): { [key: string]: any } => {
      const [fromControl, toControl] = [
        group.controls[from],
        group.controls[to],
      ];
      if (!actualComparator(fromControl.value, toControl.value)) {
        this._addErrorToControl(fromControl, name, from);
        return { [name]: message };
      } else {
        this._removeErrorFromControl(fromControl, name);
      }
    };
  }

  static lessThanValidator<T>(
    from: keyof T,
    to: keyof T,
    config?: ComparingValidatorConfig,
  ): ValidatorFn {
    return this._comparingValidator(
      from,
      to,
      (from, to) => from < to,
      'lessThan',
      `${from.toString()} must be less than ${to.toString()}`,
      config,
    );
  }

  static greaterThanValidator<T>(
    from: keyof T,
    to: keyof T,
    config?: ComparingValidatorConfig,
  ): ValidatorFn {
    return this._comparingValidator(
      from,
      to,
      (from, to) => {
        if (from === null && config.fromOptional) {
          return true;
        }
        if (to === null && config.toOptional) {
          return true;
        }
        return from > to;
      },
      'greaterThan',
      `${from.toString()} must be greater than ${to.toString()}`,
      config,
    );
  }

  static lessThanOrEqualValidator<T>(
    from: keyof T,
    to: keyof T,
    config?: ComparingValidatorConfig,
  ): ValidatorFn {
    return this._comparingValidator(
      from,
      to,
      (from, to) => from <= to,
      'lessThanOrEqual',
      `${from.toString()} must be less than or equal to ${to.toString()}`,
      config,
    );
  }

  static greaterThanOrEqualValidator<T>(
    from: keyof T,
    to: keyof T,
    config?: ComparingValidatorConfig,
  ): ValidatorFn {
    return this._comparingValidator(
      from,
      to,
      (from, to) => from >= to,
      'greaterThanOrEqual',
      `${from.toString()} must be greater than or equal to ${to.toString()}`,
      config,
    );
  }

  /**
   * Validates that either every control has a value or none
   * @param keys
   * @param isEmptyFn optional function to check if a value is empty
   */
  static allOrNoneRequiredValidator<T>(
    keys: (keyof T)[],
    isEmptyFn: (v) => boolean = isEmpty,
  ): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      // TODO: fix the types so that we dont have to cast to any
      const controls: FormControl[] = keys.map(
        (key) => group.controls[key as any],
      ) as FormControl[];
      if (controls.every((control) => control.touched)) {
        const atLeastOneSet = controls.some(
          (control) => !isEmptyFn(control.value),
        );
        if (atLeastOneSet) {
          const allSet = controls.every((control) => !isEmptyFn(control.value));
          if (!allSet) {
            for (const control of controls) {
              control.setErrors({ allOrNoneRequired: true });
            }
            return;
          }
        }
      }
      for (const control of controls) {
        this._removeErrorFromControl(control, 'allOrNoneRequired');
      }
      return null;
    };
  }

  static uniqueArrayValidator<T>(
    key: keyof T,
    ...applyTo: (keyof T)[]
  ): ValidatorFn {
    const errorName = 'uniqueArray';
    return (array: FormArray): { [key: string]: any } => {
      const controlsContainingDuplicates = array.controls.filter(
        (group: FormGroup<T>, index) => {
          const control = group.controls[key];
          const value = control.value;
          const hasDuplicate = array.controls.some((c, i) => {
            return i !== index && value === c.value[key];
          });
          if (!hasDuplicate) {
            this._removeErrorFromControl(control, errorName);
            applyTo.forEach((applyToKey) => {
              this._removeErrorFromControl(
                group.controls[applyToKey],
                errorName,
              );
            });
          } else {
            this._addErrorToControl(control, errorName, key);
            applyTo.forEach((applyToKey) => {
              this._addErrorToControl(group.controls[applyToKey], errorName);
            });
          }
          return hasDuplicate;
        },
      );
      if (controlsContainingDuplicates.length) {
        return { uniqueArray: true };
      }
      return null;
    };
  }

  private static getAgeFromControlValue(value: any): null | number {
    return typeof value === 'number'
      ? value
      : value instanceof TremazeDate
        ? value.getAge()
        : TremazeDate.deserialize(value)?.getAge();
  }

  private static _removeErrorFromControl(
    control: AbstractControl | NGAbstractControl,
    error: string,
  ) {
    if (control.hasError(error)) {
      const errors = { ...(control.errors ?? {}) };
      delete errors[error];
      if (Object.keys(errors).length) {
        control.setErrors(errors);
      } else {
        control.setErrors(null);
      }
    }
  }

  private static _addErrorToControl(
    control: AbstractControl | NGAbstractControl,
    error: string,
    value: any = true,
  ) {
    if (!control.hasError(error)) {
      const errors = { ...(control.errors ?? {}) };
      errors[error] = value;
      control.setErrors(errors);
    }
  }
}

export abstract class FormUtil {
  static hasRequiredField(
    abstractControl: AbstractControl | NGAbstractControl,
  ): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (
            FormUtil.hasRequiredField(abstractControl['controls'][controlName])
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }
}

export type FormModel<T> = Partial<{
  [P in keyof T]: AbstractControl<T[P]> | T[P];
}>;

export type FormGroupModel<T> = FormGroup<FormModel<T>>;

export type FormGroupGroup<T> = FormGroup<{
  [P in keyof Partial<T>]: FormGroup<T[P]>;
}>;
