import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DataTableColumnDirective } from './data-table-column.directive';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { PreActionsDirective } from './pre-actions.directive';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';
import { MatDialogModule } from '@angular/material/dialog';
import { FocusRemoverDirective } from './unfocus.directive';
import { DataTableLoadingBarComponent } from './data-table-parts/data-table-loading-bar.component';
import { SharedFeatureInstitutionFeatureFilterButtonModule } from '@tremaze/shared-feature-institution-feature-filter-button';
import { DataTableSearchBarComponent } from './data-table-parts/data-table-search-bar.component';
import { DataTableBottomComponent } from './data-table-parts/data-table-bottom/data-table-bottom.component';
import { DataTableActionDirective } from './data-table-action.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { DataTableCustomFilterDirective } from './data-table-custom-filter.directive';
import { ExpandingIconTextInputComponent } from '@tremaze/shared/ui/inputs/expanding-icon-text-input';
import { SharedFeatureDepartmentFeatureFilterButtonModule } from '@tremaze/shared/feature/department/feature/filter-button';
import { SharedFeatureUserFeatureFilterButtonModule } from '@tremaze/shared-feature-user-feature-filter-button';
import { MatCheckbox } from '@angular/material/checkbox';
import { DataTableGlobalActionDirective } from './data-table-global-action.directive';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    SharedPermissionUiDirectivesModule,
    MatDialogModule,
    SharedFeatureInstitutionFeatureFilterButtonModule,
    MatMenuModule,
    MatCardModule,
    ExpandingIconTextInputComponent,
    SharedFeatureDepartmentFeatureFilterButtonModule,
    SharedFeatureUserFeatureFilterButtonModule,
    MatCheckbox,
  ],
  declarations: [
    DataTableComponent,
    DataTableColumnDirective,
    PreActionsDirective,
    FocusRemoverDirective,
    DataTableLoadingBarComponent,
    DataTableSearchBarComponent,
    DataTableBottomComponent,
    DataTableActionDirective,
    DataTableCustomFilterDirective,
    DataTableGlobalActionDirective,
  ],
  exports: [
    DataTableComponent,
    DataTableColumnDirective,
    PreActionsDirective,
    DataTableActionDirective,
    DataTableCustomFilterDirective,
    DataTableGlobalActionDirective,
  ],
})
export class SharedUiDataTableModule {}
