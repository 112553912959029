import { isObservable, Observable, of } from 'rxjs';

/**
 * Ensures that the value is an observable.
 * @param value
 */
export function ensureObservable<T>(
  value: T | Observable<T>,
  createVia: (v: T) => Observable<T> = of,
): Observable<T> {
  return isObservable(value) ? value : createVia(value);
}
