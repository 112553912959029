import { Injectable } from '@angular/core';
import {
  EventParticipationType,
  EventScope,
  EventStatus,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSource,
  DefaultREADDataSource,
} from '@tremaze/shared/util-http';
import { DateRange, TremazeDate } from '@tremaze/shared/util-date';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import {
  ParticipationCriteriaDataSource,
  ParticipationCriteriaDataSourceMethodsPaginatedOptions,
} from '@tremaze/shared/feature/participation-criteria/data-access';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';

export type EventTypes = 'EVENT' | 'HIGHLIGHT';

export type EventDateRange = DateRange | [TremazeDate];

export abstract class EventREADDataSource extends DefaultREADDataSource<TremazeEvent> {
  abstract getEventsInDateRange(
    dateRange: EventDateRange,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    instIds?: string[],
    departmentIds?: string[],
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]>;

  abstract getAllParticipationTypes(): Observable<EventParticipationType[]>;
}

export interface EventScopeOptions {
  scope?: EventScope;
}

export type EventPublishScopeOptions = EventScopeOptions & {
  scope: Omit<EventScope, 'FROM_DATE'>;
};

export abstract class EventCRUDDataSource
  extends DefaultCRUDDataSource<TremazeEvent>
  implements EventREADDataSource
{
  abstract deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions & EventScopeOptions,
    notifyUsers?: boolean,
  ): Observable<boolean>;

  abstract getEventsInDateRange(
    dateRange: EventDateRange,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions,
    instIds?: string[],
    departmentIds?: string[],
    userIds?: string[],
    maxResults?: number,
    status?: EventStatus[],
  ): Observable<TremazeEvent[]>;

  abstract switchEventPublished(
    event: Pick<TremazeEvent, 'id' | 'published'>,
    options?: EventPublishScopeOptions,
    notifyUsers?: boolean,
  ): Observable<boolean>;

  abstract updateEventStatus(id: string, status: EventStatus): Observable<void>;

  abstract getAllParticipationTypes(): Observable<EventParticipationType[]>;

  abstract getCancellationReasonForEvent(
    eventId: string,
  ): Observable<string | null | undefined>;

  abstract updateCancellationReasonForEvent(
    eventId: string,
    reason: string,
  ): Observable<void>;
}

@Injectable({ providedIn: 'root' })
export class RemoteEventParticipationCriteriaDataSource extends ParticipationCriteriaDataSource {
  protected controller = '/eventCriteria';
  protected filterFields: ['NAME'];

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  create(
    i: ParticipationCriteria,
    options?: DataSourceMethodsCreateOptions<ParticipationCriteria>,
  ): Observable<ParticipationCriteria> {
    return super.create(i, {
      ...(options || {}),
      endpoint: '/add',
      method: 'PUT',
    });
  }

  edit(
    i: ParticipationCriteria,
    options?: DataSourceMethodsEditOptions<ParticipationCriteria>,
  ): Observable<ParticipationCriteria> {
    return super.edit(i, {
      ...(options || {}),
      endpoint: '/edit',
      method: 'PUT',
    });
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions,
  ): Observable<ParticipationCriteria> {
    return super.getFreshById(id, { ...(options || {}), endpoint: '/get' });
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions,
  ): Observable<boolean> {
    return super.deleteById(id, { ...(options || {}), endpoint: '/delete' });
  }

  getPaginated(
    options?: ParticipationCriteriaDataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ParticipationCriteria>> {
    switch (options?.mandatory) {
      case true:
        return super.getPaginated({
          ...(options || {}),
          endpoint: '/findAllCriteria',
        });
      case false:
        return super.getPaginated({
          ...(options || {}),
          endpoint: '/findAllNotCriteria',
        });
      default:
        return super.getPaginated({ ...(options || {}), endpoint: '/all' });
    }
  }
}
