import { Component } from '@angular/core';

@Component({
  selector: 'tremaze-report-available-svg',
  template: `
    <svg
      id="Ebene_2"
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="53.33"
      viewBox="0 0 500 666.67"
    >
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path
            d="m270.8,0H41.67C18.75,0,0,18.75,0,41.67v583.33c0,22.92,18.75,41.67,41.67,41.67h416.67c22.92,0,41.67-18.75,41.67-41.67V229.2c0-20.03-7.96-39.24-22.12-53.4L324.2,22.12c-14.16-14.16-33.37-22.12-53.4-22.12Z"
            fill="#e06064"
          />
          <path
            d="m499.79,224.58c-2.29-20.83-20-37.08-41.46-37.08h-104.17c-22.92,0-41.67-18.75-41.67-41.67V41.67c0-21.46-16.25-39.06-37.08-41.46,18.33,1.04,35.62,8.75,48.75,21.88l153.75,153.75c13.13,13.13,20.83,30.42,21.87,48.75Z"
            fill="#ed6e6e"
          />
          <path
            d="m260.42,270.83h-83.33c-17.26,0-31.25,13.99-31.25,31.25v208.33c0,17.26,13.99,31.25,31.25,31.25s31.25-13.99,31.25-31.25v-52.08h52.08c51.7,0,93.75-42.05,93.75-93.75s-42.05-93.75-93.75-93.75Zm0,125h-52.08v-62.5h52.08c17.23,0,31.25,14.02,31.25,31.25s-14.02,31.25-31.25,31.25Z"
            fill="#c64450"
          />
        </g>
      </g>
    </svg>
  `,
})
export class ReportAvailableSvgComponent {}
