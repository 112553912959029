import {
  animate,
  animateChild,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

const itemAnimation = trigger('item', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.95)' }),
    animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate('150ms ease-out', style({ opacity: 0, transform: 'scale(.95)' })),
  ]),
]);

const parentAnimation = trigger('parent', [
  transition('void => *', [
    query(':scope > *', [stagger(15, [animateChild()])], { optional: true }),
  ]),
]);

const listAnimation = trigger('list', [
  transition(':leave', [animateChild()]),
  transition(':enter', [animateChild()]),
]);

const selectedItemsButtonAnimation = trigger('selectedItemsButton', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100%)' }),
    animate(
      '200ms ease-out',
      style({ opacity: 1, transform: 'translateY(0)' }),
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate(
      '200ms ease-out',
      style({ opacity: 0, transform: 'translateY(100%)' }),
    ),
  ]),
]);

export default {
  itemAnimation,
  parentAnimation,
  listAnimation,
  selectedItemsButtonAnimation,
};
