import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Output,
} from '@angular/core';
import { DragCounterReferenceService } from './drag-counter-reference.service';

@Directive({
  selector: '[tremazeFileDropInDropZone]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'file-drop-in-drop-zone',
  },
  exportAs: 'fileDropInDropZone',
})
export class FileDropInDropZoneDirective {
  private readonly _refService = inject(DragCounterReferenceService);

  @Output() readonly filesDropped = new EventEmitter<File[]>();

  @HostBinding('class.file-drop-in-drop-zone--Dragging')
  get isDraggingFiles() {
    return this._refService.dragCounter > 0;
  }

  @HostListener('window:dragenter', ['$event'])
  private _onDragEnter(event: DragEvent) {
    this._refService.dragCounter++;
  }

  @HostListener('window:dragleave', ['$event'])
  private _onDragLeave(event: DragEvent) {
    this._refService.dragCounter--;
  }

  @HostListener('window:drop', ['$event'])
  private _onDrop(event: DragEvent) {
    this._refService.dragCounter = 0;
  }

  @HostListener('dragover', ['$event'])
  private _onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this._refService.dragCounter = 0;

    if (event.dataTransfer?.files.length) {
      this.filesDropped.emit(Array.from(event.dataTransfer.files));
    }
  }
}
