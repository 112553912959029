export class FillableVariable {
  public value: string | undefined;

  constructor(
    public readonly displayName: string,
    public readonly name: string,
    value: string | undefined,
  ) {
    this.value = value ?? '-';
  }
}
