import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
/**
 * Permissions are grouped in a 2d array. Permission within an array are interpreted as AND,
 * the arrays themselves as OR ([[X AND Y AND Z] OR [A AND B AND C]])
 */
import { Observable } from 'rxjs';
import { Meta } from '@tremaze/shared/models';

export type PrivilegeName =
  | 'USER_WRITE'
  | 'INFORMATION_READ'
  | 'INFORMATION_READ_DEPARTMENT'
  | 'INFORMATION_READ_USER'
  | 'INFORMATION_READ_REFERENCE_CLIENT'
  | 'INFORMATION_DELETE'
  | 'INFORMATION_DELETE_DEPARTMENT'
  | 'INFORMATION_DELETE_REFERENCE_CLIENT'
  | 'INFORMATION_WRITE'
  | 'INFORMATION_WRITE_DEPARTMENT'
  | 'INFORMATION_WRITE_REFERENCE_CLIENT'
  | 'TAG_WRITE'
  | 'INST_MANAGE_READ'
  | 'EVENT_PUBLISH'
  | 'EVENT_PUBLISH_DEPARTMENT'
  | 'EVENT_PUBLISH_REFERENCE_CLIENT'
  | 'CHAT_MANAGE'
  | 'INFORMATION_PUBLISH'
  | 'INFORMATION_PUBLISH_DEPARTMENT'
  | 'INFORMATION_PUBLISH_REFERENCE_CLIENT'
  | 'FILE_STORAGE_READ'
  | 'CLIENT_READ'
  | 'EMPLOYEE_READ'
  | 'CLIENT_READ_REFERENCE_CLIENT'
  | 'EVENT_READ'
  | 'EVENT_READ_USER'
  | 'EVENT_READ_DEPARTMENT'
  | 'EVENT_READ_REFERENCE_CLIENT'
  | 'USER_READ'
  | 'USER_DELETE'
  | 'EMPLOYEE_DETAILS'
  | 'CLIENT_DETAILS'
  | 'EMPLOYEE_DETAILS_REFERENCE_CLIENT'
  | 'CLIENT_DETAILS_REFERENCE_CLIENT'
  | 'TAG_READ'
  | 'CHAT_USE'
  | 'INST_MANAGE_WRITE'
  | 'EVENT_DELETE'
  | 'EVENT_DELETE_DEPARTMENT'
  | 'EVENT_DELETE_REFERENCE_CLIENT'
  | 'BASE_DATA_ROLES'
  | 'INST_BASE_DATA_WRITE'
  | 'FILE_STORAGE_WRITE'
  | 'TAG_DELETE'
  | 'EVENT_WRITE'
  | 'EVENT_WRITE_DEPARTMENT'
  | 'EVENT_WRITE_REFERENCE_CLIENT'
  | 'INST_MANAGE_DELETE'
  | 'INST_BASE_DATA_DELETE'
  | 'FILE_STORAGE_DELETE'
  | 'BASE_DATA_EDIT'
  | 'MANAGE_REFERENCE_CLIENTS'
  | 'FAMILY_MANAGE_REFERENCE_CLIENT'
  | 'FAMILY_MANAGE'
  | 'INST_READ'
  | 'DEPARTMENT_READ'
  | 'USER_TERMS_OF_USE_READ'
  | 'MANAGE_PAYMENT_DATA'
  | 'WORKFLOW_READ'
  | 'WORKFLOW_WRITE'
  | 'WORKFLOW_PUBLISH'
  | 'WORKFLOW_DELETE'
  | 'FORM_READ'
  | 'FORM_WRITE'
  | 'FORM_DELETE'
  | 'FORM_PUBLISH'
  | 'FORM_SUBMISSIONS_READ'
  | 'FORM_SUBMISSIONS_COMPLETE'
  | 'WORKFLOW_MANAGE'
  | 'CONTACT_DIRECTORY_READ'
  | 'FORM_SUBMISSIONS_READ_REFERENCE_CLIENT'
  | 'FORM_SUBMISSIONS_COMPLETE_REFERENCE_CLIENT'
  | 'EVENT_CLIENT_WRITE'
  | 'EVENT_EMPLOYEE_WRITE'
  | 'INFORMATION_CLIENT_WRITE'
  | 'INFORMATION_EMPLOYEE_WRITE'
  | 'DIVISION_WRITE'
  | 'FILE_STORAGE_READ_CLIENT'
  | 'FILE_STORAGE_READ_REFERENCE_CLIENT';

@staticImplements<Deserializable<Privilege>>()
export class Privilege {
  constructor(
    readonly id: string = null,
    readonly name: PrivilegeName = null,
    readonly viewName: string = null,
  ) {}

  static deserialize(data: any): Privilege {
    return !data ? null : new Privilege(data.id, data.name, data.viewName);
  }
}

@staticImplements<Deserializable<Role>>()
export class Role {
  constructor(
    readonly id: string = null,
    readonly meta: Meta = null,
    public name: string = null,
    public viewName: string = null,
    public privileges: Privilege[] = [],
    readonly defaultRole = false,
  ) {}

  static deserialize(data: any): Role {
    return !data
      ? null
      : new Role(
          data.id,
          Meta.deserialize(data.meta),
          data.name,
          data.viewName,
          data.privileges?.map((p: any) => Privilege.deserialize(p)),
          data.defaultRole,
        );
  }

  public static displayWith(role: Role) {
    if (role) {
      return role.viewName ? role.viewName : role.name;
    }
    return undefined;
  }

  public privilegeSet(priv: Privilege): boolean {
    if (!priv) {
      return false;
    }
    return this.privileges.some((p) => p.name === priv.name);
  }

  public addPrivilege(priv: Privilege) {
    if (priv) {
      if (!this.privilegeSet(priv)) {
        this.privileges.push(priv);
      }
    }
  }

  public addPrivileges(privs: Privilege[]) {
    this.privileges = [...this.privileges, ...privs];
  }

  public removePrivilege(priv: Privilege) {
    if (priv) {
      this.privileges.forEach((v, i) => {
        if (v.name === priv.name) {
          this.privileges.splice(i, 1);
        }
      });
    }
  }

  public isSame(role: Role) {
    if (!role) {
      return false;
    }
    return (
      this.privileges.every((v: Privilege) => {
        return role.privileges.some((p) => {
          return v.id === p.id;
        });
      }) &&
      role.privileges.every((v) => {
        return this.privileges.some((p) => {
          return v.id === p.id;
        });
      })
    );
  }
}

export type TzPermissionRequestInstId = string | string[];

export type TzPermissionRequestListing =
  | PrivilegeName
  | PrivilegeName[][]
  | boolean;

export interface TzPermissionRequestIPerms {
  instIds:
    | TzPermissionRequestInstId
    | 'ANY'
    | Observable<TzPermissionRequestInstId>;
  perms: TzPermissionRequestListing;
}

export type TzPermissionRequestIPermsListing =
  | (Partial<TzPermissionRequestIPerms> &
      Omit<TzPermissionRequestIPerms, 'perms'>)
  | TzPermissionRequestIPerms[];

interface PartialPermissionRequest {
  gPerms: TzPermissionRequestListing;
  iPerms?: TzPermissionRequestIPermsListing;
}

type FullPermissionRequest = Partial<PartialPermissionRequest> &
  Omit<PartialPermissionRequest, 'gPerms'>;

export type TzPermissionRequest =
  | PartialPermissionRequest
  | FullPermissionRequest
  | boolean;

export class TremazePermissionRequest implements PartialPermissionRequest {
  constructor(
    public gPerms: TzPermissionRequestListing,
    public iPerms?: TzPermissionRequestIPermsListing,
  ) {}

  addInstitutionPermissions(iPerms: TzPermissionRequestIPermsListing) {
    this.iPerms = iPerms;
    return this;
  }

  addSingleInstitutionSinglePermission(
    privilegeName: PrivilegeName,
    instId?: string,
  ) {
    if (instId) {
      this.iPerms = [{ instIds: instId, perms: privilegeName }];
    }
    return this;
  }
}
