<div class="table-wrapper__container">
  <mat-card appearance="raised">
    <tremaze-data-table-loading-bar
      [loading$]="loading$"
    ></tremaze-data-table-loading-bar>

    <tremaze-data-table-search-bar
      (filterReset)="filterReset.emit()"
      [filterConfig]="filterConfig"
    ></tremaze-data-table-search-bar>

    <!-- DATA_TABLE_CONTENT -->
    <div class="table-wrapper__container__table-wrapper">
      @if (initialSort$ | async; as initialSort) {
        <table
          [dataSource]="data$"
          [matSortActive]="initialSort?.active"
          [matSortDirection]="initialSort?.direction"
          [trackBy]="trackByRowId"
          (matSortChange)="onSortChange($event)"
          mat-table
          matSort
          [class.dense-actions]="showActionsDense"
          [class.rows-clickable]="showPointerCursorForRows"
        >
          <caption *ngIf="caption">
            {{
              caption
            }}
          </caption>

          @if (enableSelection) {
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
          }

          <ng-container
            *ngFor="let col of columnDefinitions"
            [matColumnDef]="col.columnName"
          >
            <!--suppress TypeScriptValidateTypes -->
            <th
              *matHeaderCellDef
              [disableClear]="col.disableSortClear"
              [disabled]="!col.sortable"
              [mat-sort-header]="col.columnName"
              [start]="col.sortStart"
              mat-header-cell
            >
              {{ col.displayName || '' }}
            </th>
            <td *matCellDef="let row; let i = index" mat-cell>
              <ng-container
                [ngTemplateOutletContext]="{ $implicit: row }"
                [ngTemplateOutlet]="col.columnTemplate || noTemplate"
              >
              </ng-container>
              <ng-template #noTemplate>
                {{ row[col.displayName] }}
              </ng-template>
            </td>
          </ng-container>
          <!-- DATA_TABLE_ACTIONS -->
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell></th>
            <!--suppress HtmlDeprecatedAttribute -->
            <td *matCellDef="let row" mat-cell>
              <ng-container *ngIf="!groupActionsIntoMenu; else menuTrigger">
                <ng-container *ngFor="let actionDef of actionDefinitions">
                  <ng-template
                    [ngTemplateOutletContext]="{ $implicit: row }"
                    [ngTemplateOutlet]="actionDef.template"
                  ></ng-template>
                </ng-container>
              </ng-container>

              <ng-template #menuTrigger>
                <button
                  mat-icon-button
                  color="accent"
                  matTooltip="Aktionen"
                  [matMenuTriggerFor]="actionsMenu"
                  [matMenuTriggerData]="{ row: row }"
                >
                  <span class="lnr lnr-chevron-down"></span>
                </button>
              </ng-template>

              <ng-template
                [ngTemplateOutletContext]="{ $implicit: row }"
                [ngTemplateOutlet]="preActions?.template"
              ></ng-template>
              <ng-container *ngIf="!hideEditButton">
                <button
                  (click)="onClickEditButton(row)"
                  *tremazeHasPermission="getEditPermissionsForRow$(row) | async"
                  matTooltip="{{ getItemName(row) }} bearbeiten"
                  color="primary"
                  mat-icon-button
                >
                  <span
                    [class]="editIconButtonIconName"
                    class="lnr lnr-pencil"
                  ></span>
                </button>
              </ng-container>

              <ng-container *ngIf="!hideDeleteButton">
                <button
                  (click)="onClickDeleteButton(row)"
                  *tremazeHasPermission="
                    getDeletePermissionsForRow$(row) | async
                  "
                  matTooltip="{{ getItemName(row) }} löschen"
                  color="primary"
                  mat-icon-button
                >
                  <span
                    [class]="deleteIconButtonIconName"
                    class="lnr lnr-trash2"
                  ></span>
                </button>
              </ng-container>
            </td>
          </ng-container>

          <tr
            *matHeaderRowDef="displayedColumns; sticky: true"
            @rowAnimation
            mat-header-row
          ></tr>
          <tr
            *matRowDef="let row; columns: displayedColumns"
            @rowAnimation
            (click)="onClickRow($event, row)"
            mat-row
          ></tr>
        </table>
      }
    </div>
  </mat-card>
</div>

<mat-card appearance="raised" style="padding: 0">
  <tremaze-data-table-bottom
    [pageSizes]="pageSizes"
    [initialPageSize]="initialPageSize"
    [permissionConfig]="permissionConfig"
    [hideCreateButton]="hideCreateButton"
  >
    <ng-container slot="actions">
      <ng-content select="[tremazeDataTableGlobalAction]"></ng-content>
    </ng-container>
  </tremaze-data-table-bottom>
</mat-card>

<mat-menu #actionsMenu="matMenu">
  <ng-template matMenuContent let-row="row">
    <ng-container *ngFor="let actionDef of actionDefinitions">
      <ng-template
        [ngTemplateOutletContext]="{ $implicit: row }"
        [ngTemplateOutlet]="actionDef.template"
      ></ng-template>
    </ng-container>
  </ng-template>
</mat-menu>
