import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { Observable } from 'rxjs';

export abstract class FolderViewDataSource {
  abstract deletesFiles(...ids: string[]): Observable<void>;

  abstract deleteDirectories(...ids: string[]): Observable<void>;

  abstract createDirectory(p: {
    directoryName: string;
    parentId: string;
  }): Observable<DirStorage>;

  abstract getDirectoryByAbsolutePath(path: string): Observable<DirStorage>;

  abstract getFilesForDirectory(directoryId: string): Observable<FileStorage[]>;

  abstract getSubDirectoriesByAbsolutePath(
    absolutPath: string,
  ): Observable<DirStorage[]>;

  abstract searchDirsAndFiles(p: {
    searchValue: string;
    searchFromDirId?: string;
  }): Observable<(FileStorage | DirStorage)[]>;

  abstract moveFilesAndDirectoriesToPath(p: {
    fileIds: string[];
    folderIds: string[];
    absolutePath: string;
  }): Observable<void>;

  abstract uploadFilesToDirectory(p: {
    files: File[];
    destinationDirId: string;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage[]>;

  abstract uploadFilesToPath(p: {
    files: File[];
    absolutePath: string;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage[]>;

  abstract updateDirectoryName(p: {
    directoryId: string;
    newName: string;
  }): Observable<DirStorage>;
}
