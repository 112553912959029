import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
} from '@tremaze/shared/util-http';
import { Department } from '@tremaze/shared/feature/department/types';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { PrivilegeName } from '@tremaze/shared/permission/types';

@Injectable({ providedIn: 'root' })
export class RemoteCCDepartmentDataSource extends DefaultCRUDDataSourceImpl<Department> {
  protected controller = 'cc/departments';
  protected filterFields = ['NAME'];
  protected deserializer = Department.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  create(
    { name, institution }: Department,
    options?: DataSourceMethodsCreateOptions<Department>,
  ): Observable<Department> {
    const payload = {
      name: name,
      instId: institution?.id ?? options.instIds?.[0],
    };
    return super.create(payload as any, options);
  }

  edit(
    { id, name }: Department,
    options?: DataSourceMethodsEditOptions<Department>,
  ): Observable<Department> {
    const payload = { id, name };
    return super.edit(payload as any, options);
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<Department>,
    forPrivilege?: PrivilegeName[],
  ): Observable<Pagination<Department>> {
    options ||= {};
    if (forPrivilege?.length) {
      options.controller = '/permissions/departments';
      options.q = {
        ...(options?.q ?? {}),
        privileges: forPrivilege?.join(','),
      };
    }
    return super.getPaginated(options);
  }
}
