import { Injectable } from '@angular/core';
import {
  distinctUntilKeyChanged,
  map,
  merge,
  Observable,
  of,
  shareReplay,
} from 'rxjs';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { HttpClient } from '@angular/common/http';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';

@Injectable({
  providedIn: 'root',
})
export class FileStoragePermissionsService {
  private dirCache: { [dirId: string]: Observable<string[]> } = {};

  constructor(
    private readonly _http: HttpClient,
    private _authService: AuthV2Service,
  ) {
    merge([
      this._authService.authenticatedUser$.pipe(
        distinctUntilKeyChanged('userId'),
      ),
      this._authService.activeTenant$.pipe(distinctUntilKeyChanged('id')),
    ]).subscribe((r) => (this.dirCache = {}));
  }

  getPermissionsForDir(id: string): Observable<string[]> {
    return this._http.get<string[]>(`dirs/shared/${id}/permissions`);
  }

  /**
   * Returns the permissions of a user for a folder.
   * @param dirId The dirId of the {@link DirStorage}.
   */
  getPermissionsById(dirId: string): Observable<string[]> {
    if (this.dirCache[dirId]) {
      return this.dirCache[dirId];
    }

    this.dirCache[dirId] = this.getPermissionsForDir(dirId).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: false,
      }),
    );

    return this.dirCache[dirId];
  }

  /**
   * Returns if a user is allowed to delete a (@link FileStorage) or a (@link DirStorage).
   * @param target The target to check the permissions for.
   * @param parent The parent {@link DirStorage} of the target.
   */
  hasDeletePermissionForStorage(
    target: FileStorage | DirStorage,
    parent: DirStorage,
  ): Observable<boolean> {
    if (target instanceof FileStorage && parent.absolutePath === '/') {
      return of(true);
    }

    if (target instanceof DirStorage && parent.absolutePath === '/') {
      return this.getPermissionsById(target.id).pipe(
        map((perms) => perms.includes('DELETE')),
      );
    }

    return this.getPermissionsById(parent.id).pipe(
      map((perms) => perms.includes('DELETE')),
    );
  }
}
