import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DefaultValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'tremaze-folder-view-item-name-input',
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent, MatRipple],
  template: `
    <input
      class="folder-view-item-name-input__Input"
      (keyup.enter)="onClickSubmit($event)"
      (blur)="onBlur($event)"
      (keyup)="onKeyup(input.value, $event)"
      [value]="value"
      (click)="$event.stopPropagation()"
      (mousedown)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
      autofocus
      #input
    />
    <div class="folder-view-item-name-input__Actions">
      <button (click)="onClickSubmit($event)" matRipple>
        <tremaze-icon icon="lnr-check"></tremaze-icon>
      </button>

      <button (click)="onClickCancel($event)" matRipple (blur)="onBlur($event)">
        <tremaze-icon icon="lnr-cross"></tremaze-icon>
      </button>
    </div>
  `,
  styleUrls: ['./folder-view-item-name-input.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-item-name-input',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FolderViewItemNameInputComponent,
    },
  ],
})
export class FolderViewItemNameInputComponent
  extends DefaultValueAccessor
  implements OnInit
{
  private readonly __elementRef = inject(ElementRef);

  @ViewChild('input', { static: true }) input?: ElementRef<HTMLInputElement>;

  @Output() readonly submitText = new EventEmitter<string>();
  @Output() readonly cancel = new EventEmitter<void>();
  @Output() readonly lostFocus = new EventEmitter<void>();

  ngOnInit() {
    const input = this.input?.nativeElement;
    if (input) {
      input.focus();
    }
  }

  value = '';

  override writeValue(value: any) {
    const input = this.input?.nativeElement;
    this.value = value;
    if (input) {
      input.value = value;
      super.writeValue(value);
    }
  }

  onClickCancel(event: Event) {
    event.stopPropagation();
    this.cancel.emit();
  }

  onClickSubmit(event: Event) {
    event.stopPropagation();
    this.submitText.emit();
  }

  onKeyup(value: string, event: KeyboardEvent) {
    event.stopPropagation();
    this.onChange?.(value);
  }

  onBlur(event: FocusEvent) {
    const isFocusLost = event.relatedTarget == null;

    function isFocusOutsideComponent(elementRef: ElementRef) {
      return !elementRef.nativeElement.contains(event.relatedTarget);
    }

    if (isFocusLost || isFocusOutsideComponent(this.__elementRef)) {
      this.lostFocus.emit();
    }
  }
}
