import { inject, Injectable } from '@angular/core';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { firstValueFrom, Observable } from 'rxjs';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { FilePreviewOverlayService } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import {
  DocumentEditorService,
  FillableVariable,
} from '@tremaze/shared/feature/document-editor';
import { PdfViewerService } from '@tremaze/shared/feature/pdf-viewer';
import { MatDialog } from '@angular/material/dialog';
import { FolderViewUploadService } from './folder-view-upload.service';
import { OverwriteOrCreateCopyDialogComponent } from '../overwrite-or-create-copy-dialog.component';
import { blobToFile } from '@tremaze/shared/util-utilities';
import { Directory } from '@microsoft/microsoft-graph-types';

@Injectable()
export class FolderViewFileOpenerService {
  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _previewService = inject(FilePreviewOverlayService);
  private readonly _documentEditorService = inject(DocumentEditorService);
  private readonly _pdfViewerService = inject(PdfViewerService);
  private readonly _dialog = inject(MatDialog);
  private readonly _uploadService = inject(FolderViewUploadService);

  fillable = false;
  fillableVariables: FillableVariable[] = [];

  async openFile(file: FileStorage, currentDirectory$: Observable<Directory>) {
    const url = await firstValueFrom(
      this._fileStorageService.getFileDownloadURL(file),
    );

    if (file.type === 'PDF') {
      this._pdfViewerService
        .openPDF({
          pdfUrl: url,
          pdfName: file.fileViewname,
        })
        .componentInstance.save.subscribe((blob) => {
          this._uploadBlob(blob, file, currentDirectory$);
        });
      return;
    }

    if (file.type === 'DOCUMENT') {
      this._documentEditorService
        .openDialog({
          documentName: file.fileViewname,
          documentUrl: url,
          fillable: this.fillable,
          fillableVariables: this.fillableVariables,
        })
        .componentInstance.save.subscribe((blob) => {
          this._uploadBlob(blob, file, currentDirectory$);
        });
      return;
    }

    this._previewService.open({
      data: {
        name: file.fileViewname,
        type: file.type,
        url,
      },
    });
  }

  private async _uploadBlob(
    blob: Blob,
    fileStorage: FileStorage,
    currentDirectory$: Observable<Directory>,
  ) {
    const overwriteOrCreateCopy = await firstValueFrom(
      this._selectOverwriteOrCreateCopy(),
    );
    if (!overwriteOrCreateCopy) {
      return;
    }
    const file = blobToFile(
      blob,
      fileStorage.fileViewname,
      fileStorage.fileType,
    );
    if (overwriteOrCreateCopy === 'OVERWRITE') {
      this._uploadService.overwriteFile(fileStorage.id, file);
    } else {
      this._uploadService.uploadFilesToCurrentDirectory(
        [file],
        currentDirectory$,
      );
    }
  }

  private _selectOverwriteOrCreateCopy(): Observable<
    'OVERWRITE' | 'CREATE_COPY' | undefined
  > {
    return this._dialog
      .open(OverwriteOrCreateCopyDialogComponent)
      .afterClosed();
  }
}
