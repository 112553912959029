import {
  mapPersonalConfigFilterKeyToPersonalConfigFilterSettingInputType,
  PersonalConfig,
  PersonalConfigAttributeFilterSetting,
  PersonalConfigDepartment,
  PersonalConfigFilterSetting,
  PersonalConfigInstitution,
  PersonalConfigListFilterSettingInput,
  PersonalConfigListType,
  PersonalConfigSortSetting,
  PersonalConfigUser,
} from '../types/personal-config';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { Time } from '@angular/common';
import { TremazeDate } from '@tremaze/shared/util-date';
import { HttpClient } from '@angular/common/http';

type FilterSettingsAttributeInput = {
  listType: PersonalConfigListType;
  dataType: PersonalConfigAttributeFilterSetting['dataType'];
  attribute: string;
} & (
  | {
      value1: string;
      value2?: string;
    }
  | { values: string[] }
);

export type PersonalConfigResponse = Omit<
  PersonalConfig,
  'coreWorkingHoursStart' | 'coreWorkingHoursEnd'
> & {
  coreWorkingHoursStart: string;
  coreWorkingHoursEnd: string;
  filterSettings: {
    [key in keyof PersonalConfig['filterSettings']]: PersonalConfig['filterSettings'][key] & {
      filterAttributes?: FilterSettingsAttributeInput[];
      sortAttributes?: PersonalConfigSortSetting[];
    };
  };
};

function createEmptyResponseFilterSetting<
  T extends keyof PersonalConfig['filterSettings'],
>(): PersonalConfig['filterSettings'][T] & {
  filterAttributes?: FilterSettingsAttributeInput[];
  sortAttributes?: PersonalConfigSortSetting[];
} {
  return {
    filterInstitutions: [],
    filterDepartments: [],
    filterUsers: [],
    filterSpecializations: [],
    sortAttribute: undefined,
  };
}

function createEmptyPersonalConfigResponse(): PersonalConfigResponse {
  return {
    teamMembers: [],
    filterSettings: {
      eventCalendarSettings: createEmptyResponseFilterSetting(),
      eventListSettings: createEmptyResponseFilterSetting(),
      informationListSettings: createEmptyResponseFilterSetting(),
      informationCategoryListSettings: createEmptyResponseFilterSetting(),
      goalListSettings: createEmptyResponseFilterSetting(),
      eventCategoryListSettings: createEmptyResponseFilterSetting(),
      eventTemplateListSettings: createEmptyResponseFilterSetting(),
      employeeListSettings: createEmptyResponseFilterSetting(),
      clientListSettings: createEmptyResponseFilterSetting(),
      userUnapprovedListSettings: createEmptyResponseFilterSetting(),
      approvalListSettings: createEmptyResponseFilterSetting(),
      formListSettings: createEmptyResponseFilterSetting(),
      submissionListSettings: createEmptyResponseFilterSetting(),
    },
    coreWorkingHoursStart: '',
    coreWorkingHoursEnd: '',
    eventPreviewSettings: {
      defaultExtendedUserCreation: false,
    },
  };
}

@Injectable({ providedIn: 'root' })
export class PersonalConfigDataSource {
  readonly _http = inject(HttpClient);

  saveConfig(config: PersonalConfig): Observable<void> {
    function _timeToString(time?: Time) {
      if (!time) {
        return '';
      }
      return (
        time.hours.toString().padStart(2, '0') +
        ':' +
        time.minutes.toString().padStart(2, '0') +
        ':00'
      );
    }

    function _transformValue(v: any): string {
      return v instanceof TremazeDate ? v.toJSON(null, true) : v.toString();
    }

    let mappedListFilterSettingsPayload: PersonalConfigListFilterSettingInput[] =
      [];
    const mappedAttributeFilterSettingsPayload: FilterSettingsAttributeInput[] =
      [];
    const mappedSortSettingsPayload: PersonalConfigSortSetting[] = [];

    const filterSettings = config.filterSettings;
    const filterSettingsKeys = Object.keys(
      filterSettings,
    ) as (keyof PersonalConfig['filterSettings'])[];

    for (const key of filterSettingsKeys) {
      const value: Partial<PersonalConfigFilterSetting> = filterSettings[key];
      if (value) {
        const type =
          mapPersonalConfigFilterKeyToPersonalConfigFilterSettingInputType(key);
        if (type) {
          const valueKeys: (keyof Partial<PersonalConfigFilterSetting>)[] =
            Object.keys(value);

          for (const subKey of valueKeys) {
            const subValue = value[subKey];
            if (subValue) {
              if (subKey === 'filterInstitutions') {
                mappedListFilterSettingsPayload =
                  mappedListFilterSettingsPayload.concat(
                    (subValue as PersonalConfigInstitution[]).map((fi) => ({
                      entityId: fi.id,
                      entityType: 'INSTITUTION',
                      listType: type,
                    })),
                  );
              } else if (subKey === 'filterDepartments') {
                mappedListFilterSettingsPayload =
                  mappedListFilterSettingsPayload.concat(
                    (subValue as PersonalConfigDepartment[]).map((fd) => ({
                      entityId: fd.id,
                      entityType: 'DEPARTMENT',
                      listType: type,
                    })),
                  );
              } else if (subKey === 'filterUsers') {
                mappedListFilterSettingsPayload =
                  mappedListFilterSettingsPayload.concat(
                    (subValue as PersonalConfigUser[]).map((fu) => ({
                      entityId: fu.id,
                      entityType: 'USER',
                      listType: type,
                    })),
                  );
              } else if (subKey === 'filterSpecializations') {
                mappedListFilterSettingsPayload =
                  mappedListFilterSettingsPayload.concat(
                    (subValue as PersonalConfigUser[]).map((fu) => ({
                      entityId: fu.id,
                      entityType: 'SPECIALIZATION',
                      listType: type,
                    })),
                  );
              } else if (subKey === 'sortAttribute') {
                const sortAttribute = subValue as PersonalConfigSortSetting;
                mappedSortSettingsPayload.push({
                  ...sortAttribute,
                  direction: sortAttribute.direction.toUpperCase() as
                    | 'asc'
                    | 'desc',
                  listType: type,
                } as any);
              } else {
                // attribute type case
                let input: FilterSettingsAttributeInput | undefined = undefined;
                const value = subValue as PersonalConfigAttributeFilterSetting;
                if (value.value !== undefined) {
                  if (Array.isArray(value.value)) {
                    input = {
                      dataType: value.dataType,
                      listType: type,
                      attribute: subKey as string,
                      values: value.value.map((v) => {
                        return _transformValue(v);
                      }),
                    };
                  } else {
                    input = {
                      dataType: value.dataType,
                      listType: type,
                      attribute: subKey as string,
                      value1: _transformValue(value.value),
                    };
                  }
                }

                if (input) {
                  mappedAttributeFilterSettingsPayload.push(input);
                }
              }
            }
          }
        } else {
          console.error(
            'Unknown filter setting key:',
            key,
            'Did you forget to add it to the mapPersonalConfigFilterKeyToPersonalConfigFilterSettingInputType function?',
          );
        }
      }
    }

    const payload = {
      teamMemberIds: config.teamMembers?.map((tm) => tm.id) ?? [],
      coreWorkingHoursStart: _timeToString(config.coreWorkingHoursStart),
      coreWorkingHoursEnd: _timeToString(config.coreWorkingHoursEnd),
      listFilterSettings: mappedListFilterSettingsPayload,
      attributeFilterSettings: mappedAttributeFilterSettingsPayload,
      attributeSortSettings: mappedSortSettingsPayload,
      eventPreviewSettings: config.eventPreviewSettings,
    };
    return this._http.put<void>('/users/me/personalConfig', payload);
  }

  private _parseTime(time?: string): Time | undefined {
    if (!time || time.length <= 1 || !time.includes(':')) {
      return undefined;
    }
    const [hours, minutes] = time.split(':').map((s) => +s);
    return { hours, minutes };
  }

  private _getParserForDataType(
    dataType: PersonalConfigAttributeFilterSetting['dataType'],
  ): (
    value?: string | null,
  ) => boolean | number | string | TremazeDate | undefined {
    try {
      switch (dataType) {
        case 'BOOLEAN':
          return (value?: string | null) =>
            value === 'true' ? true : value === 'false' ? false : undefined;
        case 'DATE':
          return (value?: string | null) =>
            value ? new TremazeDate(value) : undefined;
        case 'DOUBLE':
          return (value?: string | null) =>
            value ? parseFloat(value) : undefined;
        case 'INTEGER':
          return (value?: string | null) =>
            value ? parseInt(value, 10) : undefined;
        case 'STRING':
          return (value?: string | null) => value ?? undefined;
      }
    } catch (e) {
      return (value?: string | null) => value ?? undefined;
    }
  }

  getPersonalConfig$(): Observable<PersonalConfig> {
    return this._http
      .get<PersonalConfigResponse | null>('/users/me/personalConfig')
      .pipe(
        catchError(() => of(null)),
        map((r) => {
          r ??= createEmptyPersonalConfigResponse();

          const filterSettings = Object.values(r.filterSettings);

          for (const setting of filterSettings) {
            if (setting.filterAttributes?.length) {
              // spread the filterAttributes
              const { filterAttributes } = setting;

              for (const attribute of filterAttributes) {
                const hasValue1 =
                  'value1' in attribute && attribute.value1 !== null;
                const isArrayType = 'values' in attribute && !hasValue1;
                const parser = this._getParserForDataType(attribute.dataType);
                setting[attribute.attribute] = {
                  dataType: attribute.dataType,
                  value: isArrayType
                    ? attribute.values.map(parser)
                    : parser(attribute.value1),
                } as PersonalConfigAttributeFilterSetting;
              }
            }

            if (setting.sortAttributes?.length) {
              setting.sortAttribute = setting.sortAttributes[0];
              setting.sortAttribute.direction =
                setting.sortAttribute.direction.toLowerCase() as 'asc' | 'desc';
            }

            delete setting.filterAttributes;
            delete setting.sortAttributes;
          }

          return {
            ...r,
            coreWorkingHoursStart: this._parseTime(r.coreWorkingHoursStart),
            coreWorkingHoursEnd: this._parseTime(r.coreWorkingHoursEnd),
          };
        }),
      );
  }
}
