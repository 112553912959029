import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FolderViewComponentService } from './folder-view-component.service';
import { CommonModule } from '@angular/common';
import { FolderViewFoldersAndFilesComponent } from './folder-view-folders-and-files/folder-view-folders-and-files.component';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewActionsRowComponent } from './folder-view-actions-row/folder-view-actions-row.component';
import componentServices from './component-services';
import { PdfViewerModule } from '@tremaze/shared/feature/pdf-viewer';
import { SharedNotificationModule } from '@tremaze/shared/notification';
import { MatButton } from '@angular/material/button';
import {
  BehaviorSubject,
  distinctUntilChanged,
  firstValueFrom,
  fromEvent,
  map,
  tap,
} from 'rxjs';
import { clearAllTextSelection } from '@tremaze/shared/util-utilities';
import { FillableVariable } from '@tremaze/shared/feature/document-editor';
import { FolderViewFileOpenerService } from './services/folder-view-file-opener.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tremaze-folder-view',
  standalone: true,
  imports: [
    CommonModule,
    FolderViewFoldersAndFilesComponent,
    FolderViewActionsRowComponent,
    PdfViewerModule,
    SharedNotificationModule,
    MatButton,
  ],
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.scss'],
  providers: componentServices,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view',
  },
})
export class FolderViewComponent implements AfterViewInit, OnDestroy {
  readonly service = inject(FolderViewComponentService);
  private readonly _fileOpenerService = inject(FolderViewFileOpenerService);

  @ViewChild('scrollContainer', { static: true })
  private scrollContainer?: ElementRef<HTMLDivElement>;

  private readonly _isScrolled$ = new BehaviorSubject(false);

  readonly isScrolled$ = this._isScrolled$.asObservable();

  private _isCreatingFolder = false;

  get isCreatingFolder() {
    return this._isCreatingFolder;
  }

  private _singleSelection = false;

  @Input({ transform: booleanAttribute })
  get singleSelection() {
    return this._singleSelection;
  }

  set singleSelection(value: boolean) {
    this._singleSelection = value;
    this.service.setSingleSelection(value);
  }

  @Input() set selectionRegex(value: RegExp | undefined) {
    this.service.setSelectionRegex(value);
  }

  @Input() set fillable(value: boolean) {
    this._fileOpenerService.fillable = value;
  }
  @Input() set fillableVariables(value: FillableVariable[] | null | undefined) {
    this._fileOpenerService.fillableVariables = value ?? [];
  }

  @Output() readonly confirmSelection = new EventEmitter<FileStorage>();

  ngAfterViewInit() {
    const scrollContainer = this.scrollContainer?.nativeElement;
    if (scrollContainer) {
      fromEvent(scrollContainer, 'scroll')
        .pipe(
          map(() => scrollContainer.scrollTop > 0),
          distinctUntilChanged(),
          tap((isScrolled) => this._isScrolled$.next(isScrolled)),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.service.clearSelection();
  }

  onNavigate(path: string) {
    this.service.navigateToPath(path);
  }

  onDoubleClickDirectory(dir: DirStorage) {
    this.service.openDirectory(dir);
  }

  onClickDirectory(dir: DirStorage) {
    if (!this.singleSelection) {
      this.service.switchDirectorySelection(dir);
    }
  }

  onClickFile(file: FileStorage) {
    this.service.switchFileSelection(file);
  }

  onDoubleClickFile(file: FileStorage) {
    if (this.singleSelection) {
      this.confirmSelection.emit(file);
      return;
    }
    this.service.openFile(file);
  }

  createFolder() {
    this._isCreatingFolder = true;
  }

  cancelCreateFolder() {
    this._isCreatingFolder = false;
  }

  submitFolderName({ name, folderId }: { name: string; folderId?: string }) {
    if (!folderId) {
      this.service.createDirectory(name);
      this._isCreatingFolder = false;
    } else {
      this.service.updateDirectoryName(folderId, name);
    }
  }

  submitFileName({ name, fileId }: { name: string; fileId: string }) {
    this.service.updateFileName(fileId, name);
  }

  onFilesSelected(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.service.uploadFiles(Array.from(files));
    }
  }

  async onClickConfirmSelection() {
    const selectedFiles = await firstValueFrom(this.service.selectedFiles$);
    if (selectedFiles.length === 1) {
      this.confirmSelection.emit(selectedFiles[0]);
    }
  }

  onMoveFilesAndFolders({
    files,
    folders,
    targetPath,
  }: {
    files: FileStorage[];
    folders: DirStorage[];
    targetPath: string;
  }) {
    this.service.moveFilesAndFolders(files, folders, targetPath);
  }

  @HostListener('keydown', ['$event'])
  private _pressedCtl(event: Event) {
    if ((event as KeyboardEvent).ctrlKey) {
      clearAllTextSelection();
      document.body.style.userSelect = 'none';
    }
  }

  @HostListener('keyup.escape')
  private resetSelection() {
    this.service.clearSelection();
  }

  @HostListener('keyup', ['$event'])
  private selectAll(event: KeyboardEvent) {
    if (event.ctrlKey) {
      if (event.key === 'a') {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        this.service.selectAll();
      }
    }

    if (event.key === 'Control') {
      document.body.style.userSelect = '';
      clearAllTextSelection();
    }
  }

  onSearchValueChange(value: string) {
    this.service.setSearchValue(value);
  }
}
