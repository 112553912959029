import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentEditorDialogComponent } from './document-editor-dialog/document-editor-dialog.component';
import { FillableVariable } from './fillable-variable';

@Injectable({
  providedIn: 'root',
})
export class DocumentEditorService {
  constructor(private readonly _dialog: MatDialog) {}

  openDialog({
    documentUrl,
    documentName,
    fillable,
    fillableVariables,
  }: {
    documentUrl: string;
    documentName: string;
    fillable?: boolean;
    fillableVariables?: FillableVariable[];
  }) {
    return this._dialog.open(DocumentEditorDialogComponent, {
      width: 'min(95vw, 1800px)',
      height: 'min(95vh, 1200px)',
      disableClose: true,
      data: {
        documentUrl: documentUrl + '&cacheBuster=' + Date.now(),
        documentName,
        fillable,
        fillableVariables,
      },
    });
  }
}
