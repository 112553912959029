import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedFilesAndFoldersContextmenuComponent } from './selected-files-and-folders-contextmenu.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    MatBottomSheetModule,
    SharedPermissionUiDirectivesModule,
  ],
  declarations: [SelectedFilesAndFoldersContextmenuComponent],
})
export class SharedFileStorageUiSelectedFilesAndFoldersContextmenuModule {}
