import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsEditOptions,
  DefaultCRUDDataSource,
  DefaultCRUDDataSourceImpl,
} from '@tremaze/shared/util-http';
import {
  CustomForm,
  CustomFormSubmission,
} from '@tremaze/shared/feature/custom-forms/types';
import { map, Observable, switchMap, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { toCreateDTO, toEditDTO } from './parser';

type CustomFormCreateOptions = DataSourceMethodsCreateOptions<CustomForm>;

@Injectable({
  providedIn: 'root',
})
export class RemoteCustomFormCRUDDataSourceDefaultImpl
  extends DefaultCRUDDataSourceImpl<CustomForm>
  implements CustomFormCRUDDataSource
{
  protected controller = 'forms';

  protected deserializer = CustomForm.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions,
  ): Observable<boolean> {
    return this.http
      .put(`/${this.controller}/${id}/archive`, null)
      .pipe(map(() => true));
  }

  create(
    i: CustomForm,
    options?: CustomFormCreateOptions,
  ): Observable<CustomForm> {
    return super.create(toCreateDTO(i) as any, options);
  }

  edit(
    i: CustomForm,
    options?: DataSourceMethodsEditOptions<CustomForm>,
  ): Observable<CustomForm> {
    return super.edit(toEditDTO(i) as any, options);
  }

  getFilledOutFormForUser(
    formId: string,
    userId: string,
  ): Observable<CustomFormSubmission | null> {
    return this.http
      .get(`/${this.controller}/${formId}/${userId}`)
      .pipe(map(CustomFormSubmission.deserialize));
  }

  private _publish(formId: string): Observable<boolean> {
    return this.http.put<boolean>(
      `/${this.controller}/${formId}/publish`,
      null,
    );
  }

  private _unPublish(formId: string): Observable<boolean> {
    return this.http.put<boolean>(
      `/${this.controller}/${formId}/unpublish`,
      null,
    );
  }

  setPublished(formId: string, published: boolean): Observable<boolean> {
    if (published) {
      return this._publish(formId);
    }
    return this._unPublish(formId);
  }

  removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean> {
    return this.http
      .put<boolean>(`/${this.controller}/${formId}/removeInstitution`, null, {
        params: { instId: institutionId },
      })
      .pipe(map(() => true));
  }

  setPreviewHTMLonForm(formId: string, html: string) {
    return this.http
      .post(`/${this.controller}/${formId}/html`, html)
      .pipe(map(() => true));
  }

  getPreviewHTMLofForm(formId: string) {
    return this.http
      .get<{ id: string; html: string }>(`/${this.controller}/${formId}/html`)
      .pipe(map((response) => response.html));
  }

  setCSVExportTemplate(formId: string, template: string): Observable<boolean> {
    return this.http
      .post(`/${this.controller}/${formId}/csv`, template)
      .pipe(map(() => true));
  }

  getCSVExportTemplate(formId: string): Observable<string> {
    return this.http
      .get<{ id: string; csv: string }>(`/${this.controller}/${formId}/csv`)
      .pipe(map((response) => response?.csv ?? ''));
  }

  getFormByName(name: string): Observable<CustomForm> {
    return this.getPaginated({
      filter: {
        filterFields: ['NAME'],
        filterValue: name,
        page: 0,
        pageSize: 1,
      },
    }).pipe(
      map((response) => response.content[0]),
      switchMap((f) => this.getFreshById(f.id)),
    );
  }

  getFormsByNames(names: string[]): Observable<CustomForm[]> {
    return zip(...names.map((name) => this.getFormByName(name)));
  }
}

@Injectable({
  useExisting: RemoteCustomFormCRUDDataSourceDefaultImpl,
  providedIn: 'root',
})
export abstract class CustomFormCRUDDataSource extends DefaultCRUDDataSource<CustomForm> {
  abstract create(
    i: CustomForm,
    options?: CustomFormCreateOptions,
  ): Observable<CustomForm>;

  abstract setPublished(
    formId: string,
    published: boolean,
  ): Observable<boolean>;

  abstract getFilledOutFormForUser(
    formId: string,
    userId: string,
  ): Observable<CustomFormSubmission | null>;

  abstract removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean>;

  abstract setPreviewHTMLonForm(
    formId: string,
    html: string,
  ): Observable<boolean>;

  abstract getPreviewHTMLofForm(formId: string): Observable<string>;

  abstract setCSVExportTemplate(
    formId: string,
    template: string,
  ): Observable<boolean>;

  abstract getCSVExportTemplate(formId: string): Observable<string>;

  abstract getFormsByNames(names: string[]): Observable<CustomForm[]>;
}
