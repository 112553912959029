import { filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { Inject, Injectable, Optional } from '@angular/core';
import { BackgroundTaskService } from '@tremaze/shared/ui/progress-display';
import {
  FileStorage,
  FileStorageEntityMeta,
  FileStorageEntityType,
} from '@tremaze/shared/feature/file-storage/types';
import { isObservable, Observable, of, Subject } from 'rxjs';
import {
  FILE_STORAGE_MODULE_CONFIG,
  FileStorageModuleConfig,
} from '@tremaze/shared/feature/file-storage/module-config';
import { ActiveTenantService } from '@tremaze/shared/feature/tenant';
import { FileStorageDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import { doOnError } from '@tremaze/shared/util/rxjs';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';

@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  constructor(
    private appConfigService: AppConfigService,
    private tenantService: ActiveTenantService,
    private fileRepository: FileStorageDataSource,
    private readonly _confirmationService: ConfirmationService,
    @Optional() private backgroundTaskService: BackgroundTaskService,
    @Optional()
    @Inject(FILE_STORAGE_MODULE_CONFIG)
    private moduleConfig?: FileStorageModuleConfig,
  ) {}

  uploadAvatar(file: File): Observable<FileStorage> {
    const progress$ = new Subject<number>();

    this.backgroundTaskService.registerTask({
      name: 'Avatar hochladen',
      type: 'UPLOAD',
      progress$,
    });

    return this.fileRepository
      .uploadAvatar({
        file,
        onProgress: (progress) => {
          progress$.next(progress);
        },
      })
      .pipe(
        doOnError((e) => {
          progress$.error(e);
        }),
        finalize(() => {
          progress$.complete();
        }),
      );
  }

  uploadEntityFiles(p: {
    files: File[];
    entity: FileStorageEntityType;
    entityMeta?: FileStorageEntityMeta;
  }): Observable<FileStorage[]> {
    const progress$ = new Subject<number>();

    this.backgroundTaskService.registerTask({
      name: 'Mehrere Dateien hochladen',
      type: 'UPLOAD',
      progress$,
    });

    return this.fileRepository
      .uploadEntityFiles({
        files: p.files,
        entity: p.entity,
        entityMeta: p.entityMeta,
        onProgress: (progress) => {
          progress$.next(progress);
        },
      })
      .pipe(
        doOnError((e) => {
          progress$.error(e);
        }),
        finalize(() => {
          progress$.complete();
        }),
      );
  }

  getFileDownloadURL(file: FileStorage, width?: number): Observable<string> {
    const path$: Observable<string> = isObservable(
      this.appConfigService.basePath,
    )
      ? this.appConfigService.basePath.pipe(take(1))
      : of(this.appConfigService.basePath);
    return path$.pipe(
      switchMap((path) => {
        if (!path.endsWith('/')) {
          path += '/';
        }
        if (this.moduleConfig?.legacyDownloadPathEnabled) {
          return of(`${path}public/fileStorage/downloadFile/${file.id}`);
        }
        return this.tenantService.getTenant$().pipe(
          take(1),
          map((u) => {
            let result: string;
            if (!file.fileUploadType) {
              result = `${path}public/dir/files/download/${file.id}`;
            } else {
              result = `${path}public/files/download/${file.id}`;
            }
            if (u) {
              result += `?tenantId=${u.id}`;
            }
            if (width) {
              result += `&width=${Math.round(width)}`;
            }
            return result;
          }),
        );
      }),
    );
  }

  downloadBlob(file: FileStorage): Observable<Blob> {
    const progress$ = new Subject<number>();

    this.backgroundTaskService.registerTask({
      name: file.fileViewname,
      type: 'DOWNLOAD',
      progress$,
    });

    return this.fileRepository
      .downloadFile({
        fileId: file.id,
        fleType: file.fileType,
        onProgress: (progress) => {
          progress$.next(progress);
        },
      })
      .pipe(
        doOnError((e) => {
          progress$.error(e);
        }),
        finalize(() => {
          progress$.complete();
        }),
      );
  }

  downloadFile(file: FileStorage) {
    const progress$ = new Subject<number>();

    this.backgroundTaskService.registerTask({
      name: file.fileViewname,
      type: 'DOWNLOAD',
      progress$,
    });

    this.fileRepository
      .downloadFile({
        fileId: file.id,
        fleType: file.fileType,
        onProgress: (progress) => {
          progress$.next(progress);
        },
      })
      .pipe(
        doOnError((e) => {
          progress$.error(e);
        }),
        finalize(() => {
          progress$.complete();
        }),
      )
      .subscribe((r) => {
        const objectUrl: string = URL.createObjectURL(r);
        const a: HTMLAnchorElement = document.createElement(
          'a',
        ) as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = file.filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      });
  }

  overwriteFile(fileId: string, file: File): Observable<FileStorage> {
    const progress$ = new Subject<number>();

    this.backgroundTaskService.registerTask({
      name: 'Datei überschreiben',
      type: 'UPLOAD',
      progress$,
    });

    return this.fileRepository
      .overwriteFile({
        fileId,
        file,
        onProgress: (progress) => {
          progress$.next(progress);
        },
      })
      .pipe(
        doOnError((e) => {
          progress$.error(e);
        }),
        finalize(() => {
          progress$.complete();
        }),
      );
  }

  updateFileName(
    file: FileStorage,
    newName: string,
  ): Observable<FileStorage | undefined> {
    const fileHasExtension = file.fileViewname.includes('.');
    const newNameHasExtension = newName.includes('.');
    const fileExtension = fileHasExtension
      ? file.fileViewname.split('.').pop()
      : null;
    const newFileExtension = newNameHasExtension
      ? newName.split('.').pop()
      : null;

    const request = this.fileRepository.updateFileName({
      fileId: file.id,
      newName,
    });

    // warn if file extension changes
    if (fileExtension !== newFileExtension) {
      return this._confirmationService
        .askUserForConfirmation({
          title: 'Dateiendung ändern',
          text: 'Wenn du die Dateiendung änderst, könnte die Datei unbrauchbar werden. Bist du sicher?',
          warn: true,
        })
        .pipe(
          filter((c) => c?.confirmed),
          switchMap(() => request),
        );
    }

    return request;
  }
}
