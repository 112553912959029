import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { FolderViewDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import { NotificationService } from '@tremaze/shared/notification';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { Clipboard } from '@angular/cdk/clipboard';
import { FolderViewEventsService } from './services/folder-view-events.service';
import { FolderViewUploadService } from './services/folder-view-upload.service';
import { FolderViewSelectionService } from './services/folder-view-selection.service';
import { FolderViewDeletionService } from './services/folder-view-deletion.service';
import { FolderViewNavigationService } from './services/folder-view-navigation.service';
import { FolderViewFileOpenerService } from './services/folder-view-file-opener.service';
import {
  filterNotNullOrUndefined,
  flatFilter,
  negateBool,
} from '@tremaze/shared/util/rxjs';
import { FileStoragePermissionsService } from '@tremaze/shared/feature/file-storage/feature/file-storage-permissions';

@Injectable()
export class FolderViewComponentService implements OnDestroy {
  private readonly _dataSource = inject(FolderViewDataSource);
  private readonly _notificationService = inject(NotificationService);

  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _clipboard = inject(Clipboard);
  private readonly _permissionService = inject(FileStoragePermissionsService);

  private readonly _navigationService = inject(FolderViewNavigationService);
  private readonly _eventsService = inject(FolderViewEventsService);
  private readonly _uploadService = inject(FolderViewUploadService);
  private readonly _selectionService = inject(FolderViewSelectionService);
  private readonly _deletionService = inject(FolderViewDeletionService);
  private readonly _fileOpenerService = inject(FolderViewFileOpenerService);

  readonly selectedFiles$ =
    this._selectionService.selectedFiles$.asObservable();
  readonly selectedDirectories$ =
    this._selectionService.selectedDirectories$.asObservable();
  readonly hasSelection$ = this._selectionService.hasSelection$;
  readonly hasNoSelection$ = this.hasSelection$.pipe(negateBool());

  readonly selectionRegex$ = this._selectionService.selectionRegex$;
  readonly searchValue$ = this._eventsService.searchValueChange$;

  private readonly _isLoadingFiles$ = new BehaviorSubject<boolean>(true);
  readonly isLoadingFiles$ = this._isLoadingFiles$.asObservable();

  readonly currentDirectory$: Observable<DirStorage> =
    this._eventsService.reloadCurrentDirectory$.pipe(
      startWith(null),
      switchMap(() => {
        return this._navigationService.currentRelativePath$.pipe(
          distinctUntilChanged(),
          tap(() => this._selectionService.clearSelection()),
          switchMap((path) =>
            this._dataSource.getDirectoryByAbsolutePath(path).pipe(
              catchError(() => {
                this._notificationService.showNotification(
                  'Fehler beim Laden des Ordners',
                );
                this._navigationService.navigateToPath('/');
                return [];
              }),
            ),
          ),
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  readonly searchResults$: Observable<{
    files: FileStorage[];
    folders: DirStorage[];
  } | null> = this._eventsService.searchValueChange$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    filterNotNullOrUndefined(),
    map((searchValue) => searchValue.trim()),
    startWith(''),
    switchMap((searchValue) => {
      if (!searchValue.length) {
        return of(null);
      }
      return this.currentDirectory$.pipe(
        take(1),
        switchMap((dir) => {
          return this._dataSource.searchDirsAndFiles({
            searchValue,
            searchFromDirId: dir.id,
          });
        }),
        map((results) => {
          const folders: DirStorage[] = [];
          const files: FileStorage[] = [];
          results.forEach((result) => {
            if ('dirname' in result) {
              folders.push(result);
            } else {
              files.push(result);
            }
          });
          return { files, folders };
        }),
      );
    }),
  );

  readonly currentSubDirectories$: Observable<DirStorage[]> = combineLatest([
    this.currentDirectory$.pipe(
      distinctUntilKeyChanged('absolutePath'),
      switchMap((folder) =>
        this._eventsService.reloadCurrentDirectory$.pipe(
          startWith(null),
          switchMap(() => {
            return this._eventsService.refreshCurrentSubDirectories$.pipe(
              startWith(null),
              switchMap(() =>
                this._dataSource
                  .getSubDirectoriesByAbsolutePath(folder.absolutePath)
                  .pipe(
                    delay(0),
                    concatMap((dirs) => {
                      return this._eventsService.directoryCreated$.pipe(
                        scan((acc, dir) => [...acc, dir], dirs),
                        startWith(dirs),
                      );
                    }),
                  ),
              ),
              tap((directories) => {
                this._selectionService.setSelectedDirectories(
                  this._selectionService.selectedDirectories$.value.filter(
                    (d) => directories.some((dir) => dir.id === d.id),
                  ),
                );
              }),
            );
          }),
        ),
      ),
    ),
    this.searchResults$,
  ]).pipe(
    map(([subDirs, searchResults]) => searchResults?.folders ?? subDirs),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly currentFiles$: Observable<FileStorage[]> = combineLatest([
    this.currentDirectory$.pipe(
      distinctUntilKeyChanged('absolutePath'),
      map((dir) => dir.id),
      switchMap((id) => {
        return this._eventsService.reloadCurrentDirectory$.pipe(
          startWith(null),
          switchMap(() => {
            return this._eventsService.refreshCurrentFiles$.pipe(
              startWith(null),
              tap(() => this._isLoadingFiles$.next(true)),
              switchMap(() =>
                this._dataSource.getFilesForDirectory(id).pipe(
                  catchError(() => {
                    this._notificationService.showNotification(
                      'Fehler beim Laden der Dateien',
                    );
                    return [];
                  }),
                ),
              ),
            );
          }),
          tap((files) => {
            this._selectionService.setSelectedFiles(
              this._selectionService.selectedFiles$.value.filter((f) =>
                files.some((file) => file.id === f.id),
              ),
            );
          }),
        );
      }),
    ),
    this.searchResults$,
  ]).pipe(
    map(([files, searchResults]) => searchResults?.files ?? files),
    tap(() => this._isLoadingFiles$.next(false)),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly canWriteFileOrFolderFn$: Observable<
    (fileStorage: FileStorage | DirStorage) => Observable<boolean>
  > = this.currentDirectory$.pipe(
    map((dir) => {
      return (file: FileStorage | DirStorage) =>
        this._permissionService.hasDeletePermissionForStorage(file, dir);
    }),
  );

  ngOnDestroy() {
    this._isLoadingFiles$.complete();
  }

  openDirectory(dir: DirStorage) {
    this._navigationService.navigateToDirectory(dir);
  }

  navigateToPath(path: string) {
    this._navigationService.navigateToPath(path);
  }

  openFile(file: FileStorage) {
    this._fileOpenerService.openFile(file, this.currentDirectory$);
  }

  setSingleSelection(singleSelection: boolean) {
    this._selectionService.setSingleSelection(singleSelection);
  }

  setSelectionRegex(regex?: RegExp) {
    this._selectionService.setSelectionRegex(regex);
  }

  switchDirectorySelection(dir: DirStorage) {
    this._selectionService.switchDirectorySelection(dir);
  }

  switchFileSelection(file: FileStorage) {
    this._selectionService.switchFileSelection(file);
  }

  setSelection(files: FileStorage[], folders: DirStorage[]) {
    this._selectionService.setSelection(files, folders);
  }

  selectAll() {
    this._selectionService.selectAll(
      this.currentFiles$,
      this.currentSubDirectories$,
    );
  }

  clearSelection() {
    this._selectionService.clearSelection();
  }

  moveFilesAndFolders(
    files: FileStorage[],
    folders: DirStorage[],
    targetPath: string,
  ) {
    this._dataSource
      .moveFilesAndDirectoriesToPath({
        fileIds: files.map((f) => f.id),
        folderIds: folders.map((f) => f.id),
        absolutePath: targetPath,
      })
      .pipe(
        catchError(() => {
          this._notificationService.showNotification('Fehler beim Verschieben');
          return [];
        }),
      )
      .subscribe(() => {
        this._notificationService.showNotification('Dateien verschoben');
        if (files.length) {
          this._eventsService.refreshCurrentFiles$.next();
        }
        if (folders.length) {
          this._eventsService.refreshCurrentSubDirectories$.next();
        }
      });
  }

  deleteFile(file: FileStorage) {
    this._deletionService.deleteFile(file);
  }

  deleteDirectory(dir: DirStorage) {
    this._deletionService.deleteDirectory(dir);
  }

  downloadFile(file: FileStorage) {
    this._fileStorageService.downloadFile(file);
  }

  copyFileLink(file: FileStorage) {
    this._fileStorageService.getFileDownloadURL(file).subscribe((url) => {
      this._clipboard.copy(url);
      this._notificationService.showNotification('Link kopiert');
    });
  }

  openSelected() {
    this._selectionService.openSelected(this.currentDirectory$);
  }

  private async _directoryNameExists(name: string): Promise<boolean> {
    const currentSubDirectories = await firstValueFrom(
      this.currentSubDirectories$,
    );
    return currentSubDirectories.some(
      (dir) => dir.dirname.toLowerCase() === name.toLowerCase(),
    );
  }

  createDirectory(name: string) {
    this.currentDirectory$
      .pipe(
        take(1),
        flatFilter(() => this._directoryNameExists(name).then((v) => !v), {
          falseCallback: () => {
            this._notificationService.showNotification(
              'Ordner existiert bereits',
            );
          },
        }),
        map((dir) => dir.id),
        switchMap((id) =>
          this._dataSource.createDirectory({
            parentId: id,
            directoryName: name,
          }),
        ),
      )
      .subscribe((r) => {
        this._notificationService.showNotification('Ordner erstellt');
        this._eventsService.directoryCreated$.next(r);
      });
  }

  async updateDirectoryName(dirId: string, name: string) {
    const exists = await this._directoryNameExists(name);
    if (exists) {
      this._notificationService.showNotification('Ordner existiert bereits');
      return;
    }
    this._dataSource
      .updateDirectoryName({ directoryId: dirId, newName: name })
      .pipe(
        catchError(() => {
          this._notificationService.showNotification('Fehler beim Umbenennen');
          return [];
        }),
      )
      .subscribe(() => {
        this._eventsService.refreshCurrentSubDirectories$.next();
      });
  }

  async updateFileName(fileId: string, name: string) {
    const file = await firstValueFrom(
      this.currentFiles$.pipe(
        map((files) => files.find((f) => f.id === fileId)),
      ),
    );
    if (!file) {
      this._notificationService.showNotification('Datei nicht gefunden');
      return;
    }

    this._fileStorageService
      .updateFileName(file, name)
      .pipe(
        catchError(() => {
          this._notificationService.showNotification('Fehler beim Umbenennen');
          return [];
        }),
      )
      .subscribe(() => {
        this._eventsService.refreshCurrentFiles$.next();
      });
  }

  uploadFiles(files: File[], path?: string) {
    if (path) {
      this._uploadService.uploadFilesToPath(files, path);
      return;
    }
    this._uploadService.uploadFilesToCurrentDirectory(
      files,
      this.currentDirectory$,
    );
  }

  setSearchValue(value: string) {
    this._eventsService.searchValueChange$.next(value);
  }
}
