import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ExpandableFabItemDirective,
  ExpandableFabOverlayComponent,
  ExpandableFabTriggerForDirective,
} from '@tremaze/expandable-fab';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatButton, MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import animations from '../animations';

@Component({
  selector: 'tremaze-folder-view-actions-row',
  standalone: true,
  imports: [
    CommonModule,
    ExpandableFabTriggerForDirective,
    IconComponent,
    MatFabButton,
    ExpandableFabItemDirective,
    MatTooltip,
    ExpandableFabOverlayComponent,
    MatButton,
  ],
  template: `
    <ng-content select="[slot=start]"></ng-content>
    @if (
      !hideSelectedFilesAndFoldersButton &&
      (selectedFiles.length || selectedFolders.length)
    ) {
      <button
        mat-raised-button
        color="accent"
        @selectedItemsButton
        class="folder-view-actions-row__Selected-Items-Button"
        (click)="openSelected.emit()"
      >
        <span
          >{{ selectedFiles.length + selectedFolders.length }} Elemente
          ausgewählt</span
        >
        <tremaze-icon
          icon="lnr-chevron-up"
          class="folder-view-actions-row__Selected-Items-Button__Icon"
        ></tremaze-icon>
      </button>
    }
    <span class="spacer"></span>

    <ng-content></ng-content>

    <button [tremazeExpandableFabTriggerFor]="overlay" mat-fab color="accent">
      <tremaze-icon icon="lnr-plus"></tremaze-icon>
    </button>

    <tremaze-expandable-fab-overlay #overlay="overlay">
      <button
        mat-fab
        color="accent"
        tremazeExpandableFabItem
        matTooltip="Dateien hochladen"
        (click)="addFile.emit()"
      >
        <tremaze-icon icon="lnr-folder-upload"></tremaze-icon>
      </button>

      <button
        mat-fab
        color="accent"
        tremazeExpandableFabItem
        matTooltip="Ordner erstellen"
        (click)="addFolder.emit()"
      >
        <tremaze-icon icon="lnr-folder-plus"></tremaze-icon>
      </button>
    </tremaze-expandable-fab-overlay>
  `,
  styles: `
    :host {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
      z-index: 1;
      overflow: hidden;
      position: relative;
    }

    .folder-view-actions-row__Selected-Items-Button {
      /* need absolute positioning so that animations dont overlap */
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);

      .folder-view-actions-row__Selected-Items-Button__Icon {
        font-size: 15px;
      }
    }
  `,
  animations: [animations.selectedItemsButtonAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'folder-view-actions-row',
  },
})
export class FolderViewActionsRowComponent {
  @Input() selectedFiles: FileStorage[] = [];
  @Input() selectedFolders: DirStorage[] = [];
  @Input() hideSelectedFilesAndFoldersButton = false;

  @Output() readonly addFolder = new EventEmitter<void>();
  @Output() readonly addFile = new EventEmitter<void>();
  @Output() readonly openSelected = new EventEmitter<void>();
}
