import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { EventCRUDDataSource } from '@tremaze/shared/feature/event/data-access';
import { NotificationService } from '@tremaze/shared/notification';
import { tap } from 'rxjs';
import { doOnError } from '@tremaze/shared/util/rxjs';

@Component({
  selector: 'tremaze-event-cancellation-reason-setting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
  ],
  template: `
    <h1 matDialogTitle>Absagegrund</h1>
    <mat-dialog-content>
      <p>Hier kannst du den Absagegrund hinterlegen</p>
      <div class="form-grid">
        <mat-form-field>
          <textarea
            matInput
            placeholder="Absagegrund"
            rows="5"
            [value]="value$ | async"
            #input
          ></textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Schließen</button>
      <button mat-raised-button color="accent" (click)="save()">
        Speichern
      </button>
    </mat-dialog-actions>
  `,
})
export class EventCancellationReasonSettingDialogComponent {
  private readonly _eventId = inject(MAT_DIALOG_DATA).eventId;
  private readonly _dataSource = inject(EventCRUDDataSource);
  private readonly _notificationService = inject(NotificationService);
  private readonly _ref = inject(
    MatDialogRef<EventCancellationReasonSettingDialogComponent>,
  );

  readonly value$ = this._dataSource.getCancellationReasonForEvent(
    this._eventId,
  );

  @ViewChild('input', { static: true }) input?: ElementRef<HTMLTextAreaElement>;

  save(): void {
    const value = this.input?.nativeElement.value;

    if (value) {
      this._dataSource
        .updateCancellationReasonForEvent(this._eventId, value)
        .pipe(
          doOnError(() => {
            this._notificationService.showNotification(
              'Absagegrund konnte nicht gespeichert werden',
            );
          }),
          tap(() => {
            this._notificationService.showNotification(
              'Absagegrund gespeichert',
            );
            this._ref.close({ changed: true });
          }),
        )
        .subscribe();
    }
  }
}
