import { Injectable } from '@angular/core';
import { FileStorageDataSource } from '../file-storage-data-source';
import {
  FileStorage,
  FileStorageEntityMeta,
  FileStorageEntityType,
} from '@tremaze/shared/feature/file-storage/types';
import { Observable, of } from 'rxjs';
import { FileStorageFixtures } from '@tremaze/fixtures';

@Injectable()
export class FileStorageDataSourceMock implements FileStorageDataSource {
  updateFileName(p: {
    fileId: string;
    newName: string;
  }): Observable<FileStorage> {
    return of(FileStorageFixtures.fileStorage1);
  }
  downloadFile(p: {
    fileId: string;
    fleType?: string | undefined;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<Blob> {
    return of(new Blob());
  }

  deletesFiles(...ids: string[]): Observable<void> {
    return of();
  }

  uploadAvatar(p: {
    file: File;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage> {
    return of(FileStorageFixtures.fileStorage1);
  }

  overwriteFile(p: {
    fileId: string;
    file: File;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage> {
    return of(FileStorageFixtures.fileStorage1);
  }

  uploadEntityFiles(p: {
    files: File[];
    entity: FileStorageEntityType;
    entityMeta?: FileStorageEntityMeta | undefined;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage[]> {
    return of(
      Array.from({ length: p.files.length }, (_, i) =>
        FileStorageFixtures.generateFileStorage(i),
      ),
    );
  }
}

export const provideMockFileStorageDataSource = () => ({
  provide: FileStorageDataSource,
  useClass: FileStorageDataSourceMock,
});
